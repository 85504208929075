import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sk, cz } from './language.js';
import { Main } from './main';
import { Chat } from './chat';
import { Eshop } from './eshop';
import { History } from './history';
import { Card } from './card';
import { GDPR } from './gdpr';
import { Error } from './error';


function App() {
    var lang = sk;

    useEffect(() => {

    }, [])


    return (
        <div className="App" style={{ backgroundColor: global.theme_white, fontFamily: 'roboto', WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap" rel="stylesheet" />
            <Helmet>
                <title>{lang.title}</title>
                <meta name="description" content={lang.description} />
            </Helmet>

            <Routes>
                <Route path="/mobile/:token" element={<Main />} />
                <Route path="/mobile/eshop/:token" element={<Eshop />} />
                <Route path="/mobile/chat/:token" element={<Chat />} />
                <Route path="/mobile/history/:token" element={<History />} />
                <Route path="/mobile/card/:token" element={<Card />} />
                <Route path="/mobile/gdpr/:token" element={<GDPR typ={1} />} />
                <Route path="/mobile/vop/:token" element={<GDPR typ={0} />} />
                <Route path="/mobile/bonus_system/:token" element={<GDPR typ={3} />} />
                <Route path="/mobile/error" element={<Error />} />
                <Route path="*" element={<Error />} />
            </Routes>

        </div>
    );
}

export default App;
