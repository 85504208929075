import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk, cz } from './globals.js';
import { styles } from './styles.js';
import { faAngleLeft, faAngleRight, faArrowRight, faCalendarDays, faCalendarWeek, faEnvelope, faFaceFrown, faImage, faInfo, faInfoCircle, faList, faMessage, faPaperPlane, faShoppingBag, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { width } from '@mui/system';
import { Backdrop, Button, CircularProgress, Dialog, IconButton, Paper, TextField } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import { Calendar } from './calendar.js';
import { Select, MenuItem } from '@mui/material';


export const Reserve = (props) => {
    /*
            Detail produktu

            USAGE:
            <Reserve lang={lang} language={language} product_id={product.id} func={ReserveResult.bind(this)} />

            const ReserveResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(true);
    const [service, setService] = useState(false);
    const [time, setTime] = useState(0);
    const [typ, setTyp] = useState(0);
    const [showTermin, setShowTermin] = useState(false);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const menuHeight = 50;
    const bottomHeight = props.buy == true ? 70 : 0;

    const logo = require('./react/app/institut_logo_gold.png');

    let { func } = props;
    var lang = props.lang;

    var times = [30, 60, 90, 120, 180];
    var types = [lang.reserve_typ_1, lang.reserve_typ_2];


    useEffect(() => {

        //db_product();

        return () => {
        };

    }, []);


    const db_product = async () => {
        // detail produktu
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon_id,
                    product_id: props.product_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Press = (typ) => {
        func(typ);
    }

    const CalendarResult = (typ) => {
        if (typ == true) {
            Press(true);
        }
        setShowTermin(false);
    }

    const ChooseTermin = () => {
        var tmp = {
            id: 0,
            group_id: 0,
            label: types[typ],
            time: times[time],
            service_id: 0
        }
        setService(tmp);
        setShowTermin(true);
    }

    return (
        <Dialog open={true} fullScreen={true} >
            {showTermin == false ?
                <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                    <div style={{ ...styles.BlockRow, borderBottom: '0px solid ' + global.theme_gold_dark, height: menuHeight }}>
                        <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 45, height: 45, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.Block, height: menuHeight }}>
                            <div style={{ ...styles.Block }}>
                                <p style={{ ...styles.TextXXLarge, fontFamily: 'Kalam', fontWeight: '400' }}>{lang.reserve_termin_}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 50, height: menuHeight }}></div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight - bottomHeight, overflowY: 'scroll', backgroundColor: global.theme_gold_lighter }}>
                        <div style={{ ...styles.Block, width: '90%', paddingTop: 5 }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'center', color: global.theme_gold_dark }}>{lang.reserve_info}</p>
                            <div style={{ ...styles.Block, height: 60 }}>
                                <FontAwesomeIcon style={{ height: 30, color: global.theme_gold_dark }} icon={faCalendarDays} />
                            </div>
                            <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 5 }}>{lang.reserve_time}</p>
                            <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 5 }}>{lang.reserve_time_}</p>
                            <Select
                                value={time}
                                onChange={event => setTime(event.target.value)}
                                size='medium'
                                style={{ backgroundColor: global.theme_white, width: '100%', marginTop: 5 }}
                            >
                                {times.map((item, index) => (
                                    <MenuItem key={index} value={index}>{times[index]} minút</MenuItem>
                                ))}
                            </Select>

                            <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 40 }}>{lang.reserve_service}</p>
                            <Select
                                value={typ}
                                onChange={event => setTyp(event.target.value)}
                                size='medium'
                                style={{ backgroundColor: global.theme_white, width: '100%', marginTop: 5 }}
                            >
                                {types.map((item, index) => (
                                    <MenuItem key={index} value={index}>{types[index]}</MenuItem>
                                ))}
                            </Select>

                            <div style={{ ...styles.Block, paddingTop: 100 }}>
                                <Button onClick={() => ChooseTermin()} style={{ ...styles.ButtonWhite, width: 200 }}>
                                    {lang.reserve_date}
                                    <FontAwesomeIcon style={{ marginLeft: 10, height: 16, color: global.theme_gold_dark }} icon={faAngleRight} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Calendar salon={props.salon} user={props.user} service={service} listing={false} language={props.language} func={CalendarResult.bind(this)} />
            }
        </Dialog>
    );
}