import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk, cz } from './globals.js';
import { styles } from './styles.js';
import { faAngleLeft, faAngleRight, faCalendarDays, faCalendarWeek, faEnvelope, faFaceFrown, faImage, faInfo, faInfoCircle, faList, faMessage, faPaperPlane, faShoppingBag, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { width } from '@mui/system';
import { Backdrop, Button, CircularProgress, Dialog, IconButton, Paper, TextField } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import { Calendar } from './calendar.js';

/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

export const Debug = (data, color, typ) => {
    /*
        USAGE:
        ---------------------------------------
        Debug('text');
        Debug('text', 'gray', 'info');
        Debug('text', 'yellow', 'debug');
        Debug('text', 'red', 'warn');

    */

    if (global.testing == true) {
        if (color == undefined && typ == undefined) {
            console.log(data);
        } else {
            if (typ == 'warn') {
                console.warn('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'info') {
                console.info('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'debug') {
                console.debug('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else {
                console.log('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            }
        }
    }
}

export const GetPrice = (price) => {
    // VRATI CENU NAFORMATOVANU 0,00

    var _price = parseFloat(price).toFixed(2);
    return _price.toString().replace('.', ',');
}

export function GetImageLink(path) {
    return path.substring(0, 4) == 'http' ? path : global.web_images + "/" + path;
}


export const SaveUniqueID = (data) => {
    localStorage.setItem(global.local_unique_id, data);
}


export const LoadUniqueID = () => {
    var data = localStorage.getItem(global.local_unique_id);
    if (data != null) {
        return (data);
    } else {
        return null;
    }
}

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

/*
*
* =================================================================================================================
*
* =================================================================================================================
*
*/

export const Loading = (props) => {
    /*
        USAGE: 
                <Loading />
    */
    const logo = require('./react/app/logo_small.png');
    const size = 50;

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={6} style={{ ...styles.Block, width: size, height: size, backgroundColor: global.theme_white, borderRadius: size / 2 }}>
                <img src={logo} style={{ width: size - 15, height: size - 15 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_gold }} size={size - 4} />
            </Paper>
        </Backdrop>);
}


export const Line = (props) => {
    /* VYKRESLENIE HORIZONTALNEJ CIARY
    
        <Line width={'33%'} offset={2} color={global.theme_light_gray} />

    */

    return (
        <div style={{ width: props.width == undefined ? '25%' : props.width, height: 1, backgroundColor: props.color == undefined ? global.theme_medium_gray : props.color, marginTop: props.offset == undefined ? 5 : props.offset, marginBottom: props.offset == undefined ? 5 : props.offset }}></div>
    )
}


export const Template = (props) => {
    /*
            USAGE:
            <Template lang={lang} language={language} func={TemplateResult.bind(this)} />

            const TemplateResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <div style={{ ...styles.Block }}>

        </div>
    );
}


export const DivInfo = (props) => {
    /*
            USAGE:
            typ:    0 - notifikácia blížiaceho sa ošetrenia
                    1 - notifikácia zo systému
                    2 - nová verzia aplikácie
                    3 - oznam zo salonu

            <DivInfo lang={lang} language={language} id={id} typ={0} label={} text={} func={DivInfoResult.bind(this)} />

            const DivInfoResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);

    let { func } = props;
    var lang = props.lang;

    var backColors = [global.theme_gold_lighter, global.theme_gold_lighter, global.theme_gold_lighter, global.theme_gold_lighter];

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ, props.id);
    }

    return (
        <Paper elevation={3} style={{ ...styles.Block, width: '94%', paddingTop: 10, paddingBottom: 10, backgroundColor: backColors[props.typ], borderRadius: global.radius, marginTop: 10 }}>
            {props.label != '' ?
                <div style={{ ...styles.Block, marginBottom: 5 }}>
                    {props.typ == 0 ?
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_blue }} icon={faCalendarDays} />
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop: 3, marginLeft: 7, fontWeight: 'bold' }}>{props.label}</p>
                        </div>
                        : null}

                    {props.typ == 1 ?
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop: 5, marginLeft: 7, fontWeight: 'bold' }}><FontAwesomeIcon style={{ height: 16, color: global.theme_blue }} icon={faMessage} /> {props.label}</p>
                        </div>
                        : null}

                    {props.typ == 2 ?
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop: 2, marginLeft: 7, fontWeight: 'bold' }}>{props.label}</p>
                        </div>
                        : null}


                    {props.typ == 3 ?
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_blue }} icon={faInfoCircle} />
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop: 2, marginLeft: 7, fontWeight: 'bold' }}>{props.label}</p>
                        </div>
                        : null}
                </div>
                : null}
            <p style={{ ...styles.TextTiny }}>{props.text}</p>
            {props.sub_text != undefined && props.sub_text != '' && props.sub_text != null ?
                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{props.sub_text}</p>
                : null}
            {props.typ == 1 ?
                <Button onClick={() => Press(true)} style={{ ...styles.ButtonLight, height: 25, marginTop: 15 }}>
                    <FontAwesomeIcon style={{ height: 12, color: global.theme_black }} icon={faXmark} />
                    <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{lang.close}</p>
                </Button>
                : null}
        </Paper >
    );
}


export const DialogService = (props) => {
    /*
            USAGE:
            <DialogService lang={lang} language={language} item={serviceSelected} func={DialogServiceResult.bind(this)} />

            const DialogServiceResult = (value) => {
             
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [showReserve, setShowReserve] = useState(false);

    const logo = require('./react/app/institut_logo_gold.png');
    const img_service = require('./react/app/service.png');

    let { func } = props;
    var lang = props.lang;



    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ, props.typ);
    }

    const Reserve = () => {
        setShowReserve(true);
    }

    const CalendarResult = (typ) => {
        setShowReserve(false);
        if (typ == true) {
            Press(true);
        }
    }

    return (
        <Dialog open={true} fullScreen={true}>
            {showReserve == false ?
                <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + global.theme_gold_dark }}>
                        <div style={{ ...styles.Block, width: 50, height: 50 }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.Block, height: 50 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.service}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 50, height: 50 }}></div>
                    </div>

                    <div style={{ ...styles.Block, width: '90%', paddingBottom: 20 }}>
                        <img src={logo} style={{ width: 120, maxWidth: 120, objectFit: 'contain', marginTop: 20 }}></img>
                        <p style={{ ...styles.TextNormal, textAlign: 'center', marginTop: 20, fontWeight: 'bold' }}>{props.item.label}</p>
                        <img src={img_service} style={{ width: 80, height: 80, marginTop: 10 }} />

                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 0, fontWeight: '400' }}>{props.item.description}</p>
                        </div>
                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 20 }}>{lang.service_time}: {props.item.time} {lang.minute}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_gold_dark, marginTop: 20, fontWeight: 'bold' }}>{lang.price}: {props.item.eur} {lang.money}</p>
                        {props.salon.calendar_public == true && props.item.reserve_enabled == true ?
                            <div style={{ ...styles.Block }}>
                                <Line width={'20%'} offset={35} color={global.theme_gold_dark}></Line>
                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 0 }}>{lang.interest}</p>
                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 0 }}>{lang.reserve_termin}</p>
                                <Button onClick={() => Reserve()} style={{ ...styles.ButtonWhite, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10, color: global.theme_gold_dark, marginRight: 10 }} icon={faCalendarDays} />
                                    {lang.reserve}
                                </Button>
                            </div>
                            : null}
                    </div>
                </div>
                :
                <Calendar salon={props.salon} user={props.user} service={props.item} listing={false} language={props.language} func={CalendarResult.bind(this)} />
            }
        </Dialog>
    );
}


export const DialogMessage = (props) => {
    /*
            * Odoslať správu kozmetičke

            USAGE:
            <DialogMessage lang={lang} language={language} salon={salon} func={DialogMessageResult.bind(this)} />

            const DialogMessageResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');


    const logo = require('./react/app/institut_logo_gold.png');
    const img_service = require('./react/app/service.png');

    let { func } = props;
    var lang = props.lang;

    const db_send = async () => {
        // ODOSLAT EMAIL
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'send_email', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        salon_id: props.salon.id,
                        user_id: props.user.id,
                        message: message
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setMessage('');
                    setMessageError(lang.message_sended_text);
                } else {
                    setMessageError(lang.mail_sended_err);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ, props.typ);
    }

    const SendMessage = () => {
        setMessageError('');
        var msg = message.trim();
        if (msg != '') {
            db_send();
        }
    }


    return (
        <Dialog open={true} fullScreen={true}>
            <div style={{ ...styles.BlockCenter, height: props.height, marginTop: 0, backgroundColor: global.theme_gold_lighter, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + global.theme_gold_dark }}>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10, marginTop: 2 }} icon={faEnvelope} />
                            <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.send_message}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}></div>
                </div>
                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.send_message_text}</p>
                <p style={{ ...styles.TextNormal, marginTop: 15, fontWeight: '500', textAlign: 'center' }}>{props.salon.company}</p>
                <p style={{ ...styles.TextTiny, marginTop: 0, fontWeight: '400', textAlign: 'center' }}>{props.salon.email}</p>

                <p style={{ ...styles.TextTiny, marginTop: 15, fontWeight: '400', textAlign: 'center' }}>{lang.message_text}</p>

                <TextField
                    value={message}
                    onInput={e => setMessage(e.target.value)}
                    multiline={true}
                    rows={5}
                    size="small"
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    style={{ width: '90%' }}
                    placeholder={lang.label}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: global.theme_white, borderRadius: global.radius },
                    }}
                    variant="outlined"
                />

                <div style={{ ...styles.Block, width: '80%', height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress size={20} style={{ color: global.theme_black }}></CircularProgress>
                        :
                        <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{messageError}</p>
                    }
                </div>

                <Button onClick={() => SendMessage()} style={{ ...styles.ButtonWhite, marginTop: 10 }}>
                    <FontAwesomeIcon style={{ height: 14, color: global.theme_gold_dark, marginRight: 10 }} icon={faPaperPlane} />
                    {lang.send}
                </Button>

            </div>
        </Dialog>
    );
}



export const Newsletter = (props) => {
    /*
            USAGE:
            <Newsletter lang={lang} language={language} label={} text={} func={NewsletterResult.bind(this)} />

            const NewsletterResult = (value) => {
                
            }
            */
    const [isBusy, setBusy] = useState(false);
    const dialogHeight = 100;

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    return (
        <div onClick={() => Press(true)} style={{ ...styles.Block, width: '95%', height: dialogHeight, backgroundColor: global.theme_gold /*props.newsletter.color*/, marginTop: 0, cursor: 'pointer', borderRadius: global.radius }}>
            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.Block, width: dialogHeight + 20, height: dialogHeight + 20 }}>
                    <img src={global.web_salony + '/public/pdf/' + props.newsletter.image} style={{ width: '100%', height: '100%', maxWidth: dialogHeight - 20, maxHeight: dialogHeight - 20, objectFit: 'contain', borderRadius: global.radius / 2 }}></img>
                </div>
                <div style={{ ...styles.Block, width: '70%', height: dialogHeight + 20 }}>
                    <p style={{ ...styles.TextSmall }}>{props.newsletter.description}</p>
                    <Line color={global.theme_black} width={'15%'} ></Line>
                    <p style={{ ...styles.TextTiny }}>{props.newsletter.label}</p>
                </div>
                <div style={{ ...styles.Block, width: 50, height: dialogHeight + 20 }}>
                    <FontAwesomeIcon style={{ height: 15, color: global.theme_black }} icon={faAngleRight} />
                </div>
            </div>


        </div >
    );
}




export const MenuTop = (props) => {
    /*
            USAGE:
            <MenuTop lang={lang} func={MenuTopResult.bind(this)} />

            const MenuTopResult = (value) => {
                Debug(value)
            }
    */


    const [index, setIndex] = useState(0);

    const menuHeight = 65;
    const menuBackground = global.theme_white;

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setIndex(props.index);

        return () => {
        };

    }, [props.index]);

    const changeTab = (event, newValue) => {
        setIndex(newValue);
        func(newValue);
    };

    return (
        <div style={{ ...styles.Block, position: 'fixed', top: 0, height: menuHeight, backgroundColor: menuBackground, zIndex: 999 }}>
            <Tabs value={index} onChange={changeTab} TabIndicatorProps={{ style: { background: global.theme_dark_red } }} style={{ width: '100%' }}>
                <Tab value={0} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, width: 24, height: 24, borderRadius: 12, backgroundColor: index == 0 ? global.theme_xxgray : global.theme_light_gray }}>
                            <FontAwesomeIcon style={{ height: 15, color: global.theme_white }} icon={faInfo} />
                        </div>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', marginTop: 5, color: index == 0 ? global.theme_dark_red : global.theme_black }}>{props.lang.info}</p>
                    </div>
                } />
                <Tab value={1} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, width: 24, height: 24, borderRadius: 12, backgroundColor: index == 1 ? global.theme_xxgray : global.theme_light_gray }}>
                            <FontAwesomeIcon style={{ height: 15, color: global.theme_white }} icon={faList} />
                        </div>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', marginTop: 5, color: index == 1 ? global.theme_dark_red : global.theme_black }}>{props.lang.services}</p>
                    </div>
                } />
                <Tab value={2} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, width: 24, height: 24, borderRadius: 12, backgroundColor: index == 2 ? global.theme_xxgray : global.theme_light_gray }}>
                            <FontAwesomeIcon style={{ height: 15, color: global.theme_white }} icon={faCalendarDays} />
                        </div>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', marginTop: 5, color: index == 2 ? global.theme_dark_red : global.theme_black }}>{props.lang.calendar}</p>
                    </div>
                } />
            </Tabs>
        </div>
    );
}

export const HistoryMenuTop = (props) => {
    /*
            USAGE:
            <HistoryMenuTop lang={lang} index={index} func={HistoryMenuTopResult.bind(this)} />

            const HistoryMenuTopResult = (value) => {            
            }

    */


    const [index, setIndex] = useState(0);

    const menuHeight = 80;
    const menuBackground = global.theme_gold_lighter;

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setIndex(props.index);

        return () => {
        };

    }, [props.index]);

    const changeTab = (event, newValue) => {
        setIndex(newValue);
        func(newValue);
    };

    return (
        <div style={{ ...styles.Block, position: 'fixed', top: 0, height: menuHeight, backgroundColor: menuBackground, zIndex: 999 }}>
            <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_black }}>{props.lang.history}</p>
            <Tabs value={index} onChange={changeTab} TabIndicatorProps={{ style: { background: global.theme_dark_red } }} style={{ width: '100%' }}>
                <Tab value={0} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', color: index == 0 ? global.theme_dark_red : global.theme_black }}>{props.lang.orders}</p>
                    </div>
                } />
                <Tab value={1} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', color: index == 1 ? global.theme_dark_red : global.theme_black }}>{props.lang.products}</p>
                    </div>
                } />
                <Tab value={2} style={{ width: '33%' }} label={
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextTiny, textTransform: 'none', fontWeight: '400', color: index == 2 ? global.theme_dark_red : global.theme_black }}>{props.lang.cares}</p>
                    </div>
                } />
            </Tabs>
        </div>
    );
}


export const DialogPDF = (props) => {
    /*
            USAGE:
            <DialogPDF lang={lang} language={language} func={DialogPDFResult.bind(this)} />

            const DialogPDFResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;



    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ, props.typ);
    }

    return (
        <Dialog open={true} fullScreen={true}>
            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + global.theme_gold_dark }}>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.newsletter}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}></div>
                </div>
                <div style={{ ...styles.Block, width: '100%', height: screenHeight - 50 }}>

                    {/*
                    <Document file={{ url: global.web_salony + 'pdf/' + props.newsletter.link }}>
                        <Page pageNumber={1} />
                    </Document>
                    */}
                    <iframe width={'100%'} height={'100%'} style={{ border: '0px' }} src={global.web_salony + 'public/pdf/' + props.newsletter.link}></iframe>
                    {/*
                    <img src={global.web_salony + 'pdf/' + props.newsletter.link} style={{ width: '100%', height: '100%', maxWidth: '100%', objectFit: 'contain' }}></img>
                    */}
                </div>
            </div>
        </Dialog>
    );
}

export const DialogProduct = (props) => {
    /*
            Detail produktu

            USAGE:
            <DialogProduct lang={lang} language={language} product_id={product.id} func={DialogProductResult.bind(this)} />

            const DialogProductResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(true);
    const [product, setProduct] = useState(false);
    const [showBuy, setShowBuy] = useState(false);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const menuHeight = 50;
    const bottomHeight = props.buy == true ? 70 : 0;

    const logo = require('./react/app/institut_logo_gold.png');

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        db_product();
        return () => {
        };

    }, []);


    const db_product = async () => {
        // detail produktu
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon_id,
                    product_id: props.product_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setProduct(json.product);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Press = (typ) => {
        func(typ);
    }

    const ProductBuy = (item) => {
        setShowBuy(true);
    }

    const DialogProductBuyResult = (typ, quantity) => {
        setShowBuy(false);
        if (typ == true) {
            Press(true);
        }
    }

    return (
        <Dialog open={true} fullScreen={true} >

            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, borderBottom: '0px solid ' + global.theme_gold_dark, height: menuHeight }}>
                    <div style={{ ...styles.Block, width: 50, height: menuHeight }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: menuHeight }}>
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextNormal, marginTop: 0 }}>{product.section}</p>
                            <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{product.category}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: menuHeight }}></div>
                </div>
                <div style={{ ...styles.Block, height: screenHeight - menuHeight - bottomHeight }}>
                    {isBusy == false ?
                        <div style={{ ...styles.BlockCenter, width: '100%', height: screenHeight - menuHeight, overflowY: 'scroll' }}>
                            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gold_lighter }}>
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <p style={{ ...styles.TextNormal, textAlign: 'center', fontWeight: 'bold', color: global.theme_gold_darker }}>{product.label}</p>
                                </div>
                            </div>

                            <div style={{ ...styles.Block, width: '90%' }}>
                                <img src={global.web_images + product.image} style={{ width: '100%', maxWidth: '100%', objectFit: 'contain', marginTop: 10 }}></img>
                                <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 20, fontWeight: '400', color: global.theme_gold_dark }}>{product.note_short}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5, fontWeight: '400', color: global.theme_gold_dark }}>{product.details}</p>
                                {/*
                            <Line width={'100%'} color={global.theme_black} offset={25}></Line>
                            */}
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 40, fontWeight: '400', color: global.theme_gold_darker }}>{lang.product_popis}</p>
                                <Line width={'15%'} color={global.theme_gold} offset={10}></Line>
                                <div style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 0, fontWeight: '400', color: global.theme_gold_dark }} dangerouslySetInnerHTML={{ __html: product.note_long }} />
                            </div>

                            <div style={{ ...styles.Block, paddingTop: 40, paddingBottom: 40, marginTop: 50, backgroundColor: global.theme_gold_lighter }}>
                                <img src={logo} style={{ width: 120, objectFit: 'contain' }}></img>
                            </div>
                        </div>
                        :
                        <Loading />
                    }
                </div>
                {props.buy == true ?
                    <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: global.theme_gold }}>
                        <div style={{ ...styles.BlockRow, width: '95%' }}>
                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                <p style={{ ...styles.TextTiny }}>{lang.dph_price}</p>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_dark_red }}>{GetPrice(product.eur)} {lang.money}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '50%' }}>
                                <Button onClick={() => ProductBuy()} style={{ ...styles.ButtonDark, width: '100%' }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShoppingBag} />
                                    {lang.buy}
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>

            {showBuy == true ?
                <DialogProductBuy lang={lang} salon={props.salon} user={props.user} quantity={1} product={product} func={DialogProductBuyResult.bind(this)} />
                : null}

        </Dialog>
    );
}


export const DialogChatAdd = (props) => {
    /*
            USAGE:
            <DialogChatAdd lang={lang} language={language} salon={salon} user={user} func={DialogChatAddResult.bind(this)} />

            const DialogChatAddResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);

    var fileRef = useRef(null);

    let { func } = props;
    var lang = props.lang;

    const db_send = async () => {
        // ODOSLAT EMAIL
        if (isBusy == false) {
            setBusy(true);
            const data = new FormData();
            data.append('user_id', props.user_id);
            data.append('group_id', props.user_id);
            data.append('receive_id', props.salon_id);
            data.append('name', props.name);
            data.append('text', message);
            data.append("file_attachment", file);
            try {
                const response = await fetch(
                    global.db_url + 'chat_add', {
                    method: 'POST',
                    body: data
                }
                );
                const json = await response.json();

                if (json.ok > 0) {
                    Press(true);
                } else {
                    setMessageError(lang.chat_error);
                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const SendMessage = () => {
        setMessageError('');
        var msg = message.trim();
        if (msg != '' || file != null) {
            db_send();
        }
    }

    function AddImage(e) {
        // VYBER SUBORU
        var img = URL.createObjectURL(e.target.files[0]);
        var file = e.target.files[0];
        //var filename = file.name.toLowerCase();

        console.log(img);
        setFile(file);
        setImage(img);
    }


    function ClearImage() {
        // RESET VYBRATEHO SUBORU
        fileRef.current.value = null;
        setImage(null);
        setFile(null);
    }

    return (
        <Backdrop open={true} fullScreen={false} fullWidth={true} style={{ top: -200, borderRadius: 10 }}>
            <div style={{ ...styles.BlockCenter, width: '100%', height: props.height, backgroundColor: global.theme_gold_lighter, borderRadius: 10 }}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gold, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 50 }}>
                        <p style={{ ...styles.TextNormal, marginLeft: 10 }}>{lang.prispevok}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockLeft, width: '96%', marginTop: 10 }}>
                    <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center' }}>Text</p>
                </div>
                <TextField
                    value={message}
                    onInput={e => setMessage(e.target.value)}
                    multiline={true}
                    rows={4}
                    size="small"
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    style={{ width: '96%' }}
                    placeholder={lang.label}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: global.theme_white /*, borderRadius: global.radius*/ },
                    }}
                    variant="outlined"
                />

                {image != null ?
                    <div style={{ ...styles.Block, width: '80%', height: 100 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <img src={image} style={{ width: '100%', height: '100%', maxWidth: 80, maxHeight: 80, objectFit: 'contain', borderRadius: 10 }}></img>
                            <div style={{ ...styles.Block, width: 40, height: 80 }}>
                                <IconButton onClick={() => ClearImage()} style={{ width: 34, height: 34 }}>
                                    <FontAwesomeIcon style={{ width: 12, color: global.theme_dark_gray }} icon={faXmark} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    : null}

                {messageError != '' ?
                    <div style={{ ...styles.Block, width: '95%', height: 40 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_dark_red, marginRight: 10 }} icon={faFaceFrown} />
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_red, textAlign: 'center' }}>{messageError}</p>
                        </div>

                    </div>
                    : null}

                <div style={{ ...styles.Block, paddingBottom: 15, marginTop: image == null ? 40 : 20 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <IconButton variant="contained" component="label" style={{ width: 36, height: 36, backgroundColor: global.theme_white, marginRight: 20, borderRadius: 18 }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_gold_dark }} icon={faImage} />
                            <input ref={fileRef} onChange={AddImage} hidden accept="image/jpeg,image/png,application/pdf,video/mp4" type="file" />
                        </IconButton>
                        <Button onClick={() => SendMessage()} style={{ ...styles.ButtonDark, width: 150 }}>
                            <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                            {lang.send}
                        </Button>
                    </div>
                </div>
            </div>

            {isBusy == true ?
                <Loading />
                : null}
        </Backdrop>
    );
}


export const DialogImagePreview = (props) => {
    /*
            USAGE:
            <DialogImagePreview lang={lang} language={language} image={item} sub_label={'popis obrazku'} func={DialogImagePreviewResult.bind(this)} />

            const DialogImagePreviewResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(false);
    const titleHeight = 60;

    let { func } = props;
    var lang = props.lang;

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);


    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    return (
        <Dialog open={true} fullScreen={true}>
            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, height: titleHeight }}>
                    <div style={{ ...styles.Block, width: 50, height: titleHeight }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: titleHeight }}>
                        <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.chat}</p>
                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 0 }}>{props.sub_label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}></div>
                </div>
                <div style={{ ...styles.Block, height: screenHeight - titleHeight, backgroundColor: global.theme_black }}>
                    <img src={props.image} style={{ width: '100%', maxWidth: '100%', maxHeight: screenHeight - titleHeight, objectFit: 'contain' }}></img>
                </div>
            </div>
        </Dialog>
    );
}


export const DialogAsk = (props) => {
    /*
            USAGE:
            <DialogAsk lang={lang} language={language} label={''} text={''} func={DialogAskResult.bind(this)} />

            const DialogAskResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(false);

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    return (
        <Dialog open={true} fullScreen={false} fullWidth={true}>
            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gold_lighter }}>
                    <div style={{ ...styles.BlockLeft, height: 50 }}>
                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 16, marginLeft: 10 }}>{props.label}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', paddingTop: 20, paddingBottom: 20 }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{props.text}</p>
                </div>
                <div style={{ ...styles.Block, width: '90%', paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 100, marginRight: 10 }}>{lang.yes}</Button>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                            <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, width: 100, marginLeft: 10 }}>{lang.no}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}


export const DialogProductBuy = (props) => {
    /*
            Detail produktu

            USAGE:
            <DialogProductBuy lang={lang} salon={salon} user={user} quantity={1} product={itemSelected} card_quantity={cardQuantity} func={DialogProductBuyResult.bind(this)} />

            const DialogProductBuyResult = (value) => {             
            }

    */
    const [isBusy, setBusy] = useState(true);
    const [quantity, setQuantity] = useState(props.card_quantity);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const menuHeight = 50;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        db_buy();
        return () => {
        };

    }, []);


    const db_buy = async () => {
        // kupa produktu
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    user_id: props.user.id,
                    product_id: props.product.id,
                    quantity: props.quantity
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                // pocet kusov v kosiku
                setQuantity(json.quantity);
            } else {
                Press(false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Press = (typ) => {
        func(typ, quantity);
    }

    return (
        <Dialog open={true} fullScreen={false} fullWidth={true} >
            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 10, paddingBottom: 10 }}>
                <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.added_to_card}</p>
                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                    <FontAwesomeIcon style={{ height: 35, color: global.theme_gold_dark }} icon={faShoppingBag} />
                </div>
                <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, width: '90%', marginTop: 0 }}>{lang.continue_to_card}</Button>
                <Button onClick={() => Press(false)} style={{ ...styles.ButtonLight, width: '90%', marginTop: 10 }}>{lang.continue_to_shop}</Button>
            </div>
        </Dialog>
    );
}


export const DialogBonusSystem = (props) => {
    /*
            USAGE:
            <DialogBonusSystem lang={lang} language={language} salon_id={salon.id} func={DialogBonusSystemResult.bind(this)} />

            const DialogBonusSystemResult = (value) => {             
            }
    */
    const [isBusy, setBusy] = useState(false);
    const [text, setText] = useState('');

    const logo = require('./react/app/institut_logo_gold.png');

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV

        try {
            const response = await fetch(
                global.db_url + 'bonus_system', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setText(json.text);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ, props.typ);
    }

    return (
        <Dialog open={true} fullScreen={true}>
            <div style={{ ...styles.BlockCenter, marginTop: 0, backgroundColor: global.theme_white, paddingTop: 0 }}>
                <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + global.theme_gold_dark }}>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextNormal, marginTop: 0 }}>{lang.info}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 50, height: 50 }}></div>
                </div>
                <div style={{ ...styles.Block, width: '90%', paddingBottom: 20 }}>
                    <div style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 0, fontWeight: '400', color: global.theme_gold_dark }} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </Dialog>
    );
}