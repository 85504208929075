/*
*
* =================================================================================================================
* GDPR
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';


export const GDPR = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    // APP
    const [isBusy, setBusy] = useState(true);

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);
    const [text, setText] = useState('');

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // IMAGES
    //const logo = require('./react/app/logo.png');


    var lang = language;
    const params = useParams();
    const token = params.token;

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE

        try {
            const response = await fetch(
                global.db_url + 'gdpr', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    typ: props.typ
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    setText(json.text);
                } else {
                    navigate('/mobile/error');
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block }}>            
            <div style={{ ...styles.BlockLeft, width: '95%', fontSize: 14, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
};

