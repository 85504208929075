/*
*
* =================================================================================================================
* NAKUPNY KOSIK
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { Button, Dialog, IconButton } from '@mui/material';
import { faAngleLeft, faAngleRight, faMinus, faPlus, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { DialogProduct, GetPrice, Loading } from './items.js';
import { red } from '@mui/material/colors';


export const Card = (props) => {
    /*

        USAGE:
            <Card lang={lang} language={language} user={user} salon={salon} func={CardResult.bind(this)} />

            const CardResult = (value) => {             
            }

    */

    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isBusy, setBusy] = useState(true);
    const [redraw, setRedraw] = useState(true);

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);
    const [cardSum, setCardSum] = useState(0);
    const [cardQuantity, setCardQuantity] = useState(0);
    const [items, setItems] = useState(false);
    const [eshopEnabled, setEshopEnabled] = useState(false);
    const [showProduct, setShowProduct] = useState(false);
    const [itemSelectedID, setItemSelectedID] = useState(0);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const menuHeight = 55;
    const bottomHeight = 60;
    const imageHeight = 70;

    // IMAGES
    const bag = require('./react/app/bag.png');


    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE

        try {
            const response = await fetch(
                global.db_url + 'card', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    user_id: props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    setEshopEnabled(json.eshop_ready);
                    setCardSum(json.sum);
                    setCardQuantity(json.quantity);
                    if (json.card.length > 0) {
                        setItems(json.card);
                        setCardSum(GetSum(json.card));

                    } else {
                        setItems(false);
                        setCardSum(0);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_buy = async (id, product_id, quantity) => {
        // kupa produktu -> zmena mnozstva
        setBusy(true);
        
        try {
            const response = await fetch(
                global.db_url + 'product_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    user_id: props.user.id,
                    product_id: product_id,
                    quantity: quantity
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id == id);
                if (tmp != undefined) {
                    tmp.quantity = parseInt(tmp.quantity) + quantity;
                    setRedraw(!redraw);
                }
                setCardSum(GetSum(items));
                setCardQuantity(json.quantity);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ, cardQuantity);
    }

    const GetSum = (items) => {
        var sum = 0.00;
        items.forEach(item => {
            if (item.quantity > 0) {
                sum += parseInt(item.quantity) * parseFloat(item.eur).toFixed(2);
            }
        });

        sum = GetPrice(sum);        
        return sum;
    }

    const QuantityEdit = (id, add) => {
        // PLUS / MINUS - quantity
        var tmp = items.find(x => x.id == id);
        if (tmp != undefined) {
            db_buy(id, tmp.product_id, add);
        }
    }

    const ProductShow = (id) => {
        setItemSelectedID(id);
        setShowProduct(true);
    }

    const DialogProductResult = () => {
        setShowProduct(false);
    }


    const EmptyCard = () => {
        return (
            <div style={{ ...styles.Block, height: '100%' }}>
                <img src={bag} style={{ width: '100%', height: '100%', maxWidth: 100, maxHeight: 100, objectFit: 'contain' }}></img>
                <p style={{ ...styles.TextNormal, marginTop: 20, fontWeight: '600', textAlign: 'center' }}>{lang.empty_basket}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, fontWeight: '400', textAlign: 'center' }}>{lang.empty_basket_text}</p>
            </div>
        )
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Dialog open={true} fullScreen={true} >
            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gold_lighter, height: menuHeight }}>
                    <div style={{ ...styles.Block, width: 60, height: menuHeight }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block, height: menuHeight }}>
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <FontAwesomeIcon style={{ height: 18, color: global.theme_black, marginRight: 10 }} icon={faShoppingBag} />
                                <p style={{ ...styles.TextNormal, marginTop: 0, fontWeight: '600' }}>{lang.card}</p>
                            </div>
                            <p style={{ ...styles.TextXTiny, marginTop: 0 }}>{lang.product_list}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: menuHeight }}></div>
                </div>

                {/* ZOZNAM PRODUKTOV */}
                <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight - bottomHeight, overflowY: 'scroll' }}>
                    {items != false ?
                        items.map((item, index) => (
                            parseInt(item.quantity) > 0 ?
                                <div key={item.id} style={{ ...styles.Block, width: '95%', paddingTop: 10, paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid ' + global.theme_gold }}>
                                    <div onClick={() => ProductShow(item.product_id)} style={{ ...styles.BlockRow, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockLeft, width: imageHeight + 20 }}>
                                            <img src={global.web_images + item.image} style={{ width: '100%', height: '100%', maxWidth: imageHeight, maxHeight: imageHeight, objectFit: 'contain' }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: screenWidth - imageHeight }}>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray, marginTop: 2 }}>{item.details}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5, color: item.instock > 0 ? global.theme_dark_green : global.theme_dark_orange }}>{item.instock > 0 ? lang.instock : lang.no_instock}</p>
                                            <div style={{ ...styles.BlockRight, paddingTop: 10 }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 0, color: global.theme_dark_red, fontWeight: '600' }}><span style={{ fontWeight: '400', color: global.theme_dark_gray, marginRight: 20 }}>{lang.dph_price}</span>{GetPrice(item.eur * item.quantity)} {lang.money}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* PLUS / MINUS */}
                                    <div style={{ ...styles.BlockRight, paddingTop: 20, paddingBottom: 10 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <IconButton onClick={() => QuantityEdit(item.id, -1)} style={{ width: 34, height: 34, backgroundColor: global.theme_gold_lighter }}>
                                                <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faMinus} />
                                            </IconButton>
                                            <div style={{ ...styles.Block, width: 55, paddingTop: 6 }}>
                                                <p style={{ ...styles.TextTiny }}>{item.quantity} {lang.pieces}</p>
                                            </div>
                                            <IconButton onClick={() => QuantityEdit(item.id, 1)} style={{ width: 34, height: 34, backgroundColor: global.theme_gold_lighter }}>
                                                <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faPlus} />
                                            </IconButton>

                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        ))
                        :
                        isBusy == false ?
                            <EmptyCard />
                            : null
                    }
                </div>

                <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: global.theme_gold }}>
                    <div style={{ ...styles.BlockRow, width: '95%' }}>
                        <div style={{ ...styles.BlockLeft, width: '50%' }}>
                            <p style={{ ...styles.TextTiny }}>{lang.card_to_pay}</p>
                            <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_dark_red }}>{cardSum} {lang.money}</p>
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                            {eshopEnabled == true && items != false ?
                                <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, width: '100%' }}>
                                    {lang.to_checkout}
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                </Button>
                                : null}
                        </div>
                    </div>
                </div>
            </div>


            {showProduct == true ?
                <DialogProduct lang={lang} language={language} product_id={itemSelectedID} func={DialogProductResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </Dialog>
    )
};

