/*
*
* =================================================================================================================
* HISTORIA OBJEDNAVOK
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { DialogProduct, GetPrice, HistoryMenuTop, Loading } from './items.js';
import { Pagination } from '@mui/material';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


export const History = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isBusy, setBusy] = useState(true);
    const [index, setIndex] = useState(0);
    const [showProduct, setShowProduct] = useState(false);
    const [productID, setProductID] = useState(0);

    // ITEMS
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemsFilteredMax, setItemsFilteredMax] = useState(0);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 12;

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);


    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // IMAGES
    const img_service = require('./react/app/service.png');
    const img_service2 = require('./react/app/service1.png');

    var lang = language == 0 ? sk : cz;
    const params = useParams();
    const token = params.token;

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'history', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    setLanguage(json.language);

                    if (json.orders != false) {
                        // OBJEDNAVKY
                        var tmp = json.orders;
                        tmp.sort((a, b) => {
                            if (a.date < b.date) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        setItems(tmp);
                        PageInit(tmp);

                    } else {
                        setItems(false);
                        setItemsFiltered(false);
                    }

                } else {
                    navigate('/mobile/error');
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async () => {
        // PRODUKTY ZAKUPENE V SALONE
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'history_products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (json.purchased != false) {
                    // produkty
                    var tmp = json.purchased;
                    tmp.sort((a, b) => {
                        if (a.date < b.date) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setItems(tmp);
                    PageInit(tmp);

                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_services = async () => {
        // ABSOLVOVANE OSETRENIA
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'history_services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (json.treatments != false) {
                    // osetrenia
                    var tmp = json.treatments;
                    tmp.sort((a, b) => {
                        if (a.date < b.date) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setItems(tmp);
                    PageInit(tmp);

                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const HistoryMenuTopResult = (value) => {
        setItems(false);
        setItemsFiltered(false);

        if (value == 0) {
            db_get();
        }
        if (value == 1) {
            db_products();
        }
        if (value == 2) {
            db_services();
        }

        setIndex(value);
    }

    const GetStatus = (id) => {
        var result = '';
        var color = global.theme_dark_red;

        switch (parseInt(id)) {
            case 0:
                result = lang.order_created;
                break;
            case 1:
                result = lang.order_in_proccess;
                break;
            case 2:
                result = lang.order_ready;
                break;
            case 3:
                result = lang.order_sended;
                break;
            case 4:
                result = lang.order_finished;
                color = global.theme_dark_green;
                break;
            case 5:
                result = lang.order_canceled;
                color = global.theme_dark_gray;
                break;
        }

        return { color: color, text: result };
    }

    const Redraw = (items, p) => {
        // redraw        
        var data = items;
        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);
        setPage(p);
        GoToStart();
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);
        setItemsFilteredMax(tmp.length);
        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('item_0');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const ProductShow = (id) => {
        setProductID(id);
        setShowProduct(true);
    }

    const DialogProductResult = (value) => {
        setShowProduct(false);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.BlockTop, height: screenHeight, backgroundColor: global.theme_white }}>
            <HistoryMenuTop lang={lang} index={index} func={HistoryMenuTopResult.bind(this)} />

            <div style={{ ...styles.Block, marginTop: 0, paddingTop: 80, height: screenHeight - 80 }}>

                {index == 0 ?
                    // *********************************************************************************************************
                    // OBJEDNAVKY Z E-SHOPU
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, height: 30 }}>
                            <p style={{ ...styles.TextTiny }}>{lang.history_orders}</p>
                        </div>
                        <div style={{ ...styles.BlockCenter, height: screenHeight - 110, overflowY: 'scroll' }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <div key={item.id} id={'item_' + index} style={{ ...styles.Block, width: '95%', paddingTop: 10, paddingBottom: 10, borderRadius: global.radius / 2, backgroundColor: global.theme_gray, marginTop: 5, marginBottom: 5 }}>
                                        <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                            <p style={{ ...styles.TextNormal, fontWeight: '600', color: GetStatus(item.status_id).color }}>{GetStatus(item.status_id).text}</p>
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                                <FontAwesomeIcon style={{ height: 14, color: global.theme_gold_dark }} icon={faShoppingCart} />
                                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{lang.order}: {item.number}</p>
                                            </div>

                                            <p style={{ ...styles.TextTiny, marginTop: 10, color: global.theme_dark_gray }}>{lang.date}: {item.datum}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 3, color: global.theme_dark_gray }}>{lang.trade}: {item.trade}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 3, color: global.theme_dark_gray }}>{lang.payment}: {item.payment}</p>

                                            <div style={{ ...styles.BlockRowRaw, flexWrap: 'wrap', marginTop: 10 }}>
                                                {item.products.map(product => (
                                                    <img key={product.id} src={global.web_salony + product.image} style={{ width: '100%', height: '100%', maxWidth: 50, maxHeight: 50, objectFit: 'contain', borderRadius: 10, margin: 5 }} />
                                                ))}
                                            </div>
                                            <div style={{ ...styles.BlockRight, marginTop: 10 }}>
                                                <p style={{ ...styles.TextXXTiny, color: global.theme_black }}>{lang.dph_price}:<span style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red, marginLeft: 10 }}>{GetPrice(item.eur)} {lang.money}</span></p>
                                            </div>

                                        </div>
                                    </div>
                                ))
                                :
                                isBusy == false ?
                                    <div style={{ ...styles.Block, height: screenHeight - 110 }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.none_items}</p>
                                    </div>
                                    : null
                            }
                            {itemsFiltered != false ?
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                    <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                </div>
                                : null}
                        </div>

                    </div>

                    : null}


                {index == 1 ?
                    // *********************************************************************************************************
                    // PRODUKTY ZAKUPENE V SALONE
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, height: 30 }}>
                            <p style={{ ...styles.TextTiny }}>{lang.histrory_products}</p>
                        </div>
                        <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap', height: screenHeight - 110, overflowY: 'scroll' }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <div key={item.id} id={'item_' + index} onClick={() => ProductShow(item.service_id)} style={{ ...styles.Block, width: '50%', paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}>
                                        <div style={{ ...styles.Block, width: '90%', height: 270, borderRadius: global.radius / 2, border: '1px solid ' + global.theme_light_gray }}>
                                            <div style={{ ...styles.BlockCenter }}>
                                                <img src={global.web_salony + 'public/' + item.image} style={{ width: '100%', height: '100%', maxWidth: 120, maxHeight: 120, objectFit: 'contain', borderRadius: 10, margin: 5 }} />
                                            </div>
                                            <div style={{ ...styles.Block, width: '95%', paddingBottom: 10 }}>
                                                <p style={{ ...styles.TextSmall, marginTop: 5, color: global.theme_black }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 15, color: global.theme_dark_gray }}>{lang.buyed}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2, color: global.theme_dark_gray }}>{item.datum}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                isBusy == false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.none_items}</p>
                                    </div>
                                    : null
                            }
                            {itemsFiltered != false ?
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                    <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                </div>
                                : null}
                        </div>

                    </div>
                    : null}


                {index == 2 ?
                    // *********************************************************************************************************
                    // ABSOLVOVANE OSETRENIA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, height: 30 }}>
                            <p style={{ ...styles.TextTiny }}>{lang.histrory_services}</p>
                        </div>
                        <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap', height: screenHeight - 110, overflowY: 'scroll' }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <div key={item.id} id={'item_' + index} style={{ ...styles.Block, marginTop: 5, marginBottom: 5 }}>
                                        <div style={{ ...styles.BlockRow, width: '95%', border: '1px solid ' + global.theme_gold, paddingTop: 10, paddingBottom: 10 }} >
                                            <div style={{ ...styles.Block, width: 100 }}>
                                                <img src={item.group_id == 1 ? img_service : img_service2} style={{ width: 60, height: 60, borderRadius: 0 }} />
                                            </div>

                                            <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                <div style={{ ...styles.BlockLeft, width: '97%' }}>
                                                    {item.group_id == 1 ?
                                                        <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, margin: 0 }}>{lang.ie}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black, marginTop: 5, marginBottom: 5 }}>{item.label}</p>
                                                    <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop: 2, textAlign: 'left' }}>{item.description}</p>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 10, textAlign: 'left' }}>{lang.date}: {item.datum}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                isBusy == false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.none_items}</p>
                                    </div>
                                    : null
                            }
                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null
                                : null}
                        </div>

                    </div>

                    : null}



            </div>

            {showProduct == true ?
                <DialogProduct lang={lang} language={language} product_id={productID} salon_id={salon.id} func={DialogProductResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading />
                : null}
        </div>
    )
};

