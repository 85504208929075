// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    country: 'SK',
    ie: 'Institut Esthederm',

    client_contact: 'Kontaktné údaje',
    client_address: 'Adresa doručenia',
    name: 'Meno',
    surname: 'Priezvisko',
    number: 'Číslo',
    street: 'Ulica',
    town: 'Mesto',
    psc: 'psč',
    mobil: 'Mobil',
    email: 'E-mail',
    note: 'Poznámka',

    // MENU TAB
    info: 'Informácie',
    services: 'Služby',
    calendar: 'Kalendár',
    info: 'Informácie',
    announcement: 'Info',
    notify: 'Notifikácia',
    service_planned: 'Naplánované ošetrenie',
    close: 'Zavrieť',
    salon: 'Kozmetický salón',
    contact: 'Kontakt',
    hours: 'Otváracie hodiny',

    days: [
        '',
        'Pondelok',
        'Utorok',
        'Streda',
        'Štvrtok',
        'Piatok',
        'Sobota',
        'Nedeľa',
    ],

    ie_text1: 'Kozmetický salón',
    ie_text2: 'Ako sa všetko okolo nás mení a svet sa pohybuje rýchlejšie, ošetrenie v salóne Institut Esthederm, Vám umožňuje zatlačiť tlačidlo pauzy a ponoriť sa do maximálnej relaxácie.',
    ie_text3: 'Objavíte škálu vnemov, účinné rituály a pochopíte silu dotyku, vďaka jemným rukám.',
    ie_text4: 'Naše unikátne patenty a techniky ošetrení sú navrhnuté v spolupráci s odborníkmi v starostlivosti o pleť, k návratu krásy a mladistvosti každej pokožky a to dlhodobo.',
    service_1: 'Čistenie',
    service_2: 'Základné prevýchovné ošetrenia',
    service_3: 'Intenzívne ošetrenia',
    service_4: 'Sezónne ošetrenia',
    service_5: 'Regeneračné ošetrenia',
    service_hands: 'Ruky',
    service_body: 'Telo',
    service_face: 'Tvár',

    send_message: 'Poslať správu',
    send_message_text: 'E-mailová správa pre kozmetický salón',
    send_message_text2: 'Vaše kontaktné údaje budú odoslané spolu s e-mailom',
    message_sended: 'Správa odoslaná',
    message_sended_text: 'Vaša správa bola úspešne odoslaná. V komunikácii možete pokračovať v "Diskusii".',
    message_sended_err: 'Chyba',
    message_sended_err_text: 'Pri odosielaní správy nastala chyba. Skúste požiadavku opakovať.',
    message_text: 'Text správy',

    services_offer: 'Ponuka služieb',
    service_time: 'Dĺžka trvania',
    others_services: 'Ostatné ošetrenia',
    minute: 'Minút',

    send: 'Odoslať',
    reservations: 'Moje rezervácie',
    cancel_reservation: 'Zrušiť rezerváciu',
    canceled_reservation: 'Zrušená rezervácia',
    reservation_cancel: 'Zrušenie rezervácie',
    reservation_cancel_ask: 'Chcete zrušiť rezerváciu v kozmetickom salóne?',

    termin_next: 'Najbližší termín ošetrenia',
    planned_service: 'Naplánované ošetrenia / služby',
    planned_service_none: 'Žiadne naplánované ošetrenia',
    time: 'Čas',
    date: 'Dátum',
    money: '€',
    pieces: 'ks',
    price_buy: 'Nákupná cena',
    price_sell: 'Predajná cena',
    price: 'Cena',
    price_piece: 'Cena za 1 kus',
    quantity: 'Množstvo',
    instock: 'Skladom',
    ok: 'OK',
    loading: 'Aktualizácia údajov',
    back: 'Späť',
    products: 'Produkty',
    product: 'Produkt',
    services: 'Služby',
    service: 'Ošetrenie',
    interest: 'Máte záujem o túto službu?',
    reserve_termin: 'Rezervovať si termín v kozmetickom salóne',
    reserve_termin_: 'Rezervácia termínu',
    reserve: 'Objednať sa',
    mail_sended_err: 'Pri odosielaní e-mailu nastala chyba!',
    newsletter: 'Newsletter',

    history: 'História',
    orders: 'Objednávky',
    order: 'Objednávka',
    cares: 'Ošetrenia',

    order_created: 'Vytvorená',
    order_in_proccess: 'V príprave',
    order_ready: 'Pripravená na vydaj',
    order_sended: 'Odoslaná',
    order_finished: 'Vybavená',
    order_canceled: 'Zrušená',

    trade_: 'Doprava',
    payment_: 'Platba',
    dph_price: 'cena s DPH',

    history_orders: 'Objednávky produktov z e-shopu',
    histrory_services: 'Absolvované ošetrenia',
    histrory_products: 'Produkty zakúpené v salóne',
    buyed: 'Zakúpené',
    product_popis: 'Popis produktu',
    none_items: 'Žiadne položky',
    chat: 'Diskusia',
    chat_new: 'Nový príspevok',
    cancel: 'Zrušiť',
    prispevok: 'Príspevok',
    delete: 'Vymazať',
    chat_delete: 'Vymazanie príspevku',
    chat_delete_ask: 'Chcete vymazať publikovaný príspevok?',
    yes: 'Áno',
    no: 'Nie',
    chat_none: 'Žiadne príspevky',
    chat_error: 'Pri odosielaní nastala chyba!',
    chat_turn_off: 'Diskusia je vypnutá',

    instock: 'Skladom',
    no_instock: 'Do troch dní',
    buy: 'Kúpiť',
    categories: 'Kategórie produktov',
    categories_all: 'Všetky kategórie',

    section_face: 'Starostlivosť o tvár',
    section_face_id: 1,
    section_body: 'Starostlivosť o telo',
    section_body_id: 2,
    section_summer: 'Opaľovanie',
    section_summer_id: 3,
    section_action: 'Darčekové balenia',
    section_action_id: 7,

    category_prevychova: 'Prevýchova',
    category_prevychova_id: 1,
    category_antiage: 'Antiage',
    category_antiage_id: 2,
    category_intensive: 'Intensive',
    category_intensive_id: 3,
    category_leto: 'Leto',
    category_leto_id: 4,
    category_telo: 'Telo',
    category_telo_id: 5,
    category_cistenie: 'Čistenie',
    category_cistenie_id: 6,

    product_search: 'Hľadať v e-shope',
    search_string: 'Hľadaný reťazec',

    added_to_card: 'Pridané do košíka',
    continue_to_shop: 'Pokračovať v nákupe',
    continue_to_card: 'Pokračovať do košíka',
    card: 'Nákupný košík',
    card_sum: 'Celková cena vrátane DPH',
    back_to_shop: 'Späť do obchodu',
    to_checkout: 'K pokladni',
    checkout_step_1: 'Doprava a platba',
    checkout_step_2: 'Adresa dodania',
    checkout_step_3: 'Zhrnutie objednávky',
    trade: 'Spôsob dopravy',
    payment: 'Spôsob platby',
    free: 'zadarmo',
    product_list: 'Zoznam produktov',
    card_to_pay: 'K úhrade',
    empty_basket: 'Váš nákupný košík je prázdny',
    empty_basket_text: 'Nájdite produkt a pridajte ho do košíka',
    price_piece: 'Cena za 1ks',
    checkout: 'Pokladňa',
    continue: 'Pokračovať',
    order_note: 'Poznámka k  nákupu',
    send_order: 'Objednať s povinnosťou platby',
    sum_products: 'Suma za produkty',
    sum_to_pay: 'Suma k zaplateniu',
    gdpr: 'Ochrana osobných údajov',
    gdpr_agree_2: 'Súhlasím so spracovaním osobných údajov.',
    gdpr_agree: 'Súhlasím so všeobecnými obchodnými podmienkami a spracovaním osobných údajov.',
    required: 'Povinný údaj',

    shopping_finish_ok: 'Objednávka bola úspešne dokončená',
    shopping_finish_ok_text: 'Informácie o stave objednávky Vám budeme priebežne posielať na Váš e-mail',
    order_score: 'Nákupom ste získali',
    get_credits: 'vernostných bodov',
    bonus_info: 'Viac o vernostnom systéme',
    order_number: 'Číslo objednávky',
    order_error: 'Pri odosielaní nastala chyba!',
    order_error_: 'Skúste požiadavku opakovať.',
    bonus_system: 'Vernostný systém',
    calendar_free: 'Voľné termíny',
    reserve_time: 'Vyberte si predpokladanú dĺžku trvania',
    reserve_time_: 'ošetrenia / služby',
    reserve_date: 'Výber termínu',
    reserve_service: 'Mám záujem o',
    reserve_typ_1: 'Ošetrenie Institut esthederm',
    reserve_typ_2: 'Iná služba',
    reserve_info: 'Rezervácia termínu v kozmetickom salóne',
    reserve_waiting: 'Čaká na potvrdenie salónom!',
    reserve_listing: 'Zoznam zobrazuje len voľné termíny',
}



// **********************************************************************
// CZ - LANGUAGE
// **********************************************************************
export const cz = {
    country: 'CZ',
    ie: 'Institut Esthederm',

    client_contact: 'Kontaktní údaje',
    client_address: 'Adresa doručení',
    name: 'Jméno',
    surname: 'Příjmení',
    number: 'Číslo',
    street: 'Ulice',
    town: 'Město',
    psc: 'psč',
    mobil: 'Mobil',
    email: 'E-mail',
    note: 'Poznámka',

    // MENU TAB
    info: 'Informace',
    services: 'Služby',
    calendar: 'Kalendář',
    info: 'Informace',
    announcement: 'Info',
    notify: 'Notifikace',
    service_planned: 'Naplánované ošetření',
    close: 'Zavřít',
    salon: 'Kosmetický salon',
    contact: 'Kontakt',
    hours: 'Otevírací hodiny',

    days: [
        '',
        'Pondělí',
        'Úterý',
        'Středa',
        'Čtvrtek',
        'Pátek',
        'Sobota',
        'Neděle',
    ],

    ie_text1: 'Kosmetický salon',
    ie_text2: 'Jak se vše kolem nás mění a svět se pohybuje rychleji, ošetření v salonu Institut Esthederm, Vám umožňuje zatlačit tlačítko pauzy a ponořit se do maximální relaxace.',
    ie_text3: 'Objevíte škálu vjemů, účinné rituály a pochopíte sílu doteku, díky jemným rukám.',
    ie_text4: 'Naše unikátní patenty a techniky ošetření jsou navrženy ve spolupráci s odborníky v péči o pleť, k návratu krásy a mladistvosti každé pokožky a to dlouhodobě.',
    service_1: 'Čištění',
    service_2: 'Základní převýchovná ošetření',
    service_3: 'Intenzivní ošetření',
    service_4: 'Sezónní ošetření',
    service_5: 'Regenerační ošetření',
    service_hands: 'Ruce',
    service_body: 'Tělo',
    service_face: 'Tvář',

    send_message: 'Poslat zprávu',
    send_message_text: 'E-mailová zpráva pro kosmetický salon',
    message_sended: 'Zpráva odeslána',
    message_sended_text: 'Vaše zpráva byla úspěšně odeslána. V komunikaci můžete pokračovat v "Diskusi".',
    message_sended_err: 'Chyba',
    message_sended_err_text: 'Při odesílání zprávy nastala chyba. Zkuste požadavek opakovat.',
    message_text: 'Text zprávy',

    send: 'Odeslat',
    service_time: 'Délka trvání',
    others_services: 'Ostatní ošetření',
    minute: 'Minut',

    services_offer: 'Nabídka služeb',
    reservations: 'Moje rezervace',
    cancel_reservation: 'Zrušit rezervaci',
    canceled_reservation: 'Zrušená rezervace',
    reservation_cancel: 'Zrušení rezervace',
    reservation_cancel_ask: 'Chcete zrušit rezervaci v kosmetickém salonu?',

    termin_next: 'Nejbližší termín ošetření',
    planned_service: 'Naplánovaná ošetření / služby',
    planned_service_none: 'Žádná naplánovaná ošetření',
    time: 'Čas',
    date: 'Datum',
    money: 'Kč',
    pieces: 'ks',
    price_buy: 'Nákupní cena',
    price_sell: 'Prodejní cena',
    price: 'Cena',
    price_piece: 'Cena za 1 kus',
    quantity: 'Množství',
    instock: 'Skladem',
    ok: 'OK',
    loading: 'Aktualizace údajů',
    back: 'Zpět',
    products: 'Produkty',
    product: 'Produkt',
    services: 'Služby',
    service: 'Ošetření',
    interest: 'Máte zájem o tuto službu?',
    reserve_termin: 'Rezervovat si termín v kosmetickém salonu',
    reserve_termin_: 'Rezervace termínu',
    reserve: 'Objednat se',
    mail_sended_err: 'Při odesílání e-mailu nastala chyba!',
    newsletter: 'Newsletter',

    history: 'Historie',
    orders: 'Objednávky',
    order: 'Objednávka',
    cares: 'Ošetření',

    order_created: 'Vytvořená',
    order_in_proccess: 'V přípravě ',
    order_ready: 'Připraveno na výdej',
    order_sended: 'Odeslaná',
    order_finished: 'Ukončena',
    order_canceled: 'Zrušena',

    trade_: 'Doprava',
    payment_: 'Platba',
    dph_price: 'cena s DPH',

    history_orders: 'Objednávky produktov z e-shopu',
    histrory_services: 'Absolvovaná ošetření',
    histrory_products: 'Produkty zakoupené v salonu',
    buyed: 'Zakoupeno',
    product_popis: 'Popis produktu',
    none_items: 'Žádné položky',
    chat: 'Diskuse',
    chat_new: 'Nový příspěvek',
    cancel: 'Zrušit',
    prispevok: 'Příspěvek',
    delete: 'Vymazat',
    chat_delete: 'Vymazání příspěvku',
    chat_delete_ask: 'Chcete vymazat publikovaný příspěvek?',
    yes: 'Ano',
    no: 'Ne',
    chat_none: 'Žádné příspěvky',
    chat_error: 'Pri odosielaní nastala chyba!',
    chat_turn_off: 'Diskuse je vypnuta',

    instock: 'Skladem',
    no_instock: 'Do tří dnů',
    buy: 'Koupit',
    categories: 'Kategorie produktů',
    categories_all: 'Všechny kategorie',

    section_face: 'Péče o obličej',
    section_face_id: 4,
    section_body: 'Péče o tělo',
    section_body_id: 6,
    section_summer: 'Opalování',
    section_summer_id: 7,
    section_action: 'Dárková balení',
    section_action_id: 7,

    category_prevychova: 'Převýchova',
    category_prevychova_id: 7,
    category_antiage: 'Antiage',
    category_antiage_id: 8,
    category_intensive: 'Intensive',
    category_intensive_id: 9,
    category_leto: 'Léto',
    category_leto_id: 10,
    category_telo: 'Tělo',
    category_telo_id: 11,
    category_cistenie: 'Čistení',
    category_cistenie_id: 12,

    product_search: 'Hledat v e-shopu',
    search_string: 'Hledaný řetězec',

    added_to_card: 'Přidáno do košíku',
    continue_to_shop: 'Pokračovat v nákupu',
    continue_to_card: 'Pokračovat do košíku',
    card: 'Nákupní košík',
    card_sum: 'Celková cena včetně DPH',
    back_to_shop: 'Zpět do obchodu',
    to_checkout: 'K pokladně',
    checkout_step_1: 'Doprava a platba',
    checkout_step_2: 'Adresa dodání',
    checkout_step_3: 'Shrnutí objednávky',
    trade: 'Způsob dopravy',
    payment: 'Způsob platby',
    free: 'zdarma',
    product_list: 'Seznam produktů',
    card_to_pay: 'K úhradě',
    empty_basket: 'Váš nákupní košík je prázdný',
    empty_basket_text: 'Najděte produkt a přidejte jej do košíku',
    price_piece: 'Cena za 1ks',
    checkout: 'Pokladna',
    continue: 'Pokračovat',
    order_note: 'Poznámka k nákupu',
    send_order: 'Objednávka zavazující k platbě',
    sum_products: 'Částka za produkty',
    sum_to_pay: 'Částka k zaplacení',
    gdpr: 'Ochrana osobních údajů',
    gdpr_agree_2: 'Souhlasím se zpracováním osobních údajů.',
    gdpr_agree: 'Souhlasím s všeobecnými obchodními podmínkami a zpracováním osobních údajů.',
    required: 'Povinný údaj',

    shopping_finish_ok: 'Objednávka byla úspěšně dokončena',
    shopping_finish_ok_text: 'Informace o stavu objednávky Vám budeme průběžně zasílat na Váš e-mail',
    order_score: 'Nákupem jste získali',
    get_credits: 'věrnostních bodů',
    bonus_info: 'Více o věrnostním systému',
    order_number: 'Číslo objednávky',
    order_error: 'Při odesílání nastala chyba!',
    order_error_: 'Zkuste požadavek opakovat.',
    bonus_system: 'Věrnostní systém',
    calendar_free: 'Volné termíny',
    reserve_time: 'Vyberte si předpokládanou délku trvání',
    reserve_time_: 'ošetření / služby',
    reserve_date: 'Výběr termínu',
    reserve_service: 'Mám zájem o',
    reserve_typ_1: 'Ošetření Institut esthederm',
    reserve_typ_2: 'Jiná služba',
    reserve_info: 'Rezervace termínu v kosmetickém salonu',
    reserve_waiting: 'Čeká na potvrzení salonem!',
    reserve_listing: 'Seznam zobrazuje jen volné termíny',
}

/*
bundle identifier:
org.reactjs.native.example.$(PRODUCT_NAME:rfc1034identifier)
org.reactjs.native.example.salon-klient-app

NEW
org.reactjs.native.datadream.--PRODUCT-NAME-rfc1034identifier-
org.reactjs.native.datadream.esthedermsalon

*/