import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper, Dialog, Backdrop, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faChevronLeft, faChevronRight, faExclamationTriangle, faList, faPlus, faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { getMinutes } from 'date-fns';


export const Calendar = (props) => {
    /*
        PROPS:
            listing = {false/true} -> zobrazí len listing v dnesný den - emblended option


    */

    const navigate = useNavigate();

    const [language, setLanguage] = useState(0);
    const [isBusy, setBusy] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selected, setSelected] = useState([]);
    const [startMinutes, setStartMinutes] = useState(480);

    const [termins, setTermins] = useState([]); // zoznam z DB
    const [cells, setCells] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [days, setDays] = useState({ items: [] });
    const [typ, setTyp] = useState(props.listing == true ? 3 : 0);
    const [actualDate, setActualDate] = useState(Date());
    const [day, setDay] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [todayDay, setTodayDay] = useState(0);
    const [todayMonth, setTodayMonth] = useState(0);
    const [todayYear, setTodayYear] = useState(0);
    const [hours, setHours] = useState([]);
    const [redrawAll, setRedrawAll] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxWidth = 120;
    const boxHeight = 130;
    const dayHeight = 30;
    const hor_offset = 50; // odskok ak sa krizujú termíny;

    const minutes_offset = 120; // pri zmeneni casu od kolko pripocitat k casu do
    const start_line = '07:00'; // otvaracie hodiny OD casu
    const end_line = '21:00'; // otvaracie hodiny OD casu

    // COLORS
    const highLight = '#FFEFEF'; // DNESNY DEN - FARBA OPZADIA
    const bg_cell = '#D0E6FF'; // farba pozadia bunky
    const bg_cell_border = '#88ACD4'; // farba okraja bunky
    const bg_paper = '#FFFFFF';// '#FFF7D0'; // pozadie dna
    const bg_roung = '#AAAAAA'; // V mesacnom prehlade kruzok
    const bg_new = '#F25751'; // pridat novy zaznam
    const typ_highlight = '#8957F4'; // vyber typu - oznaceny

    const bg_colors = ['#CFEFF7', '#F4DDFF', '#FFDFDD', '#FFFDDD', '#DFFFDD', '#EEEEEE'];
    const border_colors = ['#5CA8BB', '#934CB6', '#C5332B', '#CCC127', '#68A85F', '#666666'];

    const lang_sk = {
        birth_date_holder: 'd. m. r',
        months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
        months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
        days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
        days_short: ['Ned', 'Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob'],
        days_short_label: ['Pon', 'Uto', 'Str', 'Štv', 'Pia', 'Sob', 'Ned'],
        sviatky: ['01-01', '01-06', '05-01', '05-08', '07-05', '08-29', '09-01', '09-15', '11-01', '11-17', '12-24', '12-25', '12-26'],
        date: 'Dátum',
        today: 'Dnes',
        month: 'Mesiac',
        week: 'Týždeň',
        day: 'deň',
        new: 'Nová udalosť',
        save: 'Rezervovať',
        close: 'Zavrieť',
        time_from: 'Čas od',
        from: 'od',
        to: 'do',
        note: 'poznámka k rezervácii',
        list: 'list',
        waiting: 'Čaká na potvrdenie!',
        waiting_text: 'Stlačením "Uložiť", termín potvrdíte',
        sviatok: 'sviatok',
        none: 'Žiadne voľné termíny',
        calendar_free: 'Voľné termíny',
        time: 'Čas',
        reserve_date: 'Rezervácia termínu',
        reserve_date_text: 'Vyberte si voľný termín',
        reserve_info: 'Po odoslaní požiadavky o rezerváciu termínu, kozmetický salón musí Vašu rezerváciu potvrdiť.',
    }

    const lang_cz = {
        birth_date_holder: 'd. m. r',
        months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
        months_short: ['Led.', 'Úno.', 'Bře.', 'Dub.', 'Kvě.', 'Čern.', 'Čerc.', 'Srp.', 'Zař.', 'Říj.', 'Lis.', 'Pro.'],
        days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
        days_short: ['Ned', 'Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob'],
        days_short_label: ['Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob', 'Ned'],
        sviatky: ['01-01', '01-06', '05-01', '05-08', '07-05', '08-29', '09-01', '09-15', '11-01', '11-17', '12-24', '12-25', '12-26'],
        date: 'Datum',
        today: 'Dnes',
        month: 'Měsíc',
        week: 'Týden',
        day: 'den',
        new: 'Nová událost',
        save: 'Rezervovat',
        close: 'Zavřít',
        time_from: 'Čas od',
        from: 'od',
        to: 'do',
        note: 'poznámka k rezervaci',
        list: 'list',
        waiting: 'Čeká na potvrzení!',
        waiting_text: 'Stisknutím "Uložit", termín potvrdíte',
        sviatok: 'svátek',
        none: 'Žádné volné termíny',
        calendar_free: 'Volné termíny',
        time: 'Čas',
        reserve_date: 'Rezervace termínu',
        reserve_date_text: 'Vyberte si volný termín',
        reserve_info: 'Po odeslání požadavku o rezervaci termínu, kosmetický salon musí Vaši rezervaci potvrdit.',
    }


    var lang = props.language == 0 ? lang_sk : lang_cz;


    let params = useParams();
    let { func } = props;

    useEffect(() => {

        var today = new Date();
        var d = today.getDate();
        var m = today.getMonth();
        var y = today.getFullYear();

        setTodayDay(d);
        setTodayMonth(m);
        setTodayYear(y);

        var n = 0;
        var arr = [];
        while (n < 24) {
            arr[n] = n.toString().padStart(2, '0') + ':00';
            n++;
        }
        setHours(arr);

        Today();

        // HLADANIE VOLNYCH TERMINOV
        //db_item_free('2022-08-13', 420, 1140, 60);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }



    const db_items = async (typ_, arr_months, arr_weeks, arr_day, date) => {

        var arr = [];
        if (typ_ == 0) {
            var n = 0;
            arr_months.forEach(element => {
                arr[n] = element.year + '-' + (element.month + 1).toString().padStart(2, '0') + '-' + element.day.toString().padStart(2, '0')
                n++;
            });
        }

        if (typ_ == 1) {
            var n = 0;
            arr_weeks.forEach(element => {
                arr[n] = element.year + '-' + (element.month + 1).toString().padStart(2, '0') + '-' + element.day.toString().padStart(2, '0')
                n++;
            });
        }

        if (typ_ == 2 || typ_ == 3) {
            var d = (date.getDate()).toString().padStart(2, '0');
            var m = (date.getMonth() + 1).toString().padStart(2, '0');
            var y = (date.getFullYear());
            arr[0] = y + '-' + m + '-' + d;
        }

        var space = parseInt(props.service.time);
        var space_break = 0;
        if (props.service.time_break > 0) {
            space_break = parseInt(props.service.time_break);            
        } else {
            space_break = parseInt(props.salon.reserve_break_time);            
        }
        if (space == 0) {
            space = 30;
        }

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar_free_2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    date: arr,
                    space: space + space_break,
                    space_break: space_break
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                var items = json.items;
                setTermins(items);
                Update(items, typ_, arr_months, arr_weeks, arr_day, date);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_item_store = async (data) => {
        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'calendar_store', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: props.language,
                        data: data
                    })
                })

                const json = await response.json();
                setBusy(false);
                if (json.ok > 0) {
                    Press(true);
                }


            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Update = (items, typ_, arr_months, arr_weeks, arr_day, date) => {
        // UPDATE DAT V KALENDARY PO NACITANI Z DB
        var datum = GetDate(date);
        if (typ_ == 0) {
            // MESACNY PREHLAD
            arr_months.forEach(item => {
                var d = item.year.toString().padStart(2, '0') + '-' + (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = items.filter(x => x.date == d);
                item.count = tmp.length;

                tmp.sort((a, b) => {
                    if (a.start > b.start) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                item.items = tmp;

                // hladanie sviatku                
                var d = (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = lang.sviatky.filter(x => x == d);
                if (tmp.length > 0) {
                    item.sviatok = true;
                } else {
                    item.sviatok = false;
                }
            });
            setCells(arr_months);
        }

        if (typ_ == 1) {
            // TYZDENNY PREHLAD
            arr_weeks.forEach(item => {
                var d = item.year.toString().padStart(2, '0') + '-' + (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = items.filter(x => x.date == d);
                item.count = tmp.length;

                tmp.sort((a, b) => {
                    if (a.start > b.start) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                item.items = tmp;

                // hladanie sviatku                
                var d = (item.month + 1).toString().padStart(2, '0') + '-' + item.day.toString().padStart(2, '0');
                var tmp = lang.sviatky.filter(x => x == d);
                if (tmp.length > 0) {
                    item.sviatok = true;
                } else {
                    item.sviatok = false;
                }
            });
            setWeeks(arr_weeks);
        }

        if (typ_ == 2 || typ_ == 3) {
            // DENNY PREHLAD
            var tmp = items.filter(x => x.date == datum);
            tmp.sort((a, b) => {
                if (a.start > b.start) {
                    return 1;
                } else {
                    return -1;
                }
            })
            arr_day.items = tmp;

            // hladanie sviatku                
            var d = (arr_day.month + 1).toString().padStart(2, '0') + '-' + arr_day.day.toString().padStart(2, '0');
            var tmp = lang.sviatky.filter(x => x == d);
            if (tmp.length > 0) {
                arr_day.sviatok = true;
            } else {
                arr_day.sviatok = false;
            }

            setDays(arr_day);
        }

        setRedrawAll(!redrawAll);
    }


    function Redraw(typ_, year, month, day, reload) {
        // PREKRESLENIE DO KALENDARA
        var arr_months = [];
        var arr_weeks = [];
        var arr_day = [];

        var date = new Date(year, month, day);
        setActualDate(date);

        var days_in_month = GetDays(year, month + 1);
        var day_of_week = GetDay(year, month, day);
        var first_day = GetDay(year, month, 1) == 0 ? 7 : GetDay(year, month, 1);

        setYear(year);
        setMonth(month);
        setDay(day);

        // ZISKANIE ARRAY - CELY MESIAC
        var n = 0;
        var value = false;
        var day_ = 1;
        var arr = [];
        var day_of_week_ = 0;
        while (n < first_day + days_in_month - 1) {
            if (n == first_day - 1) {
                value = true;
            }
            if (n == first_day - 1 + days_in_month) {
                value = false;
            }

            if (value == true) {
                var day_of_week_ = GetDay(year, month, day_);
            }
            var data = {
                id: n,
                active: value,
                day: value == true ? day_ : 0,
                day_name: lang.days_short[day_of_week_],
                month: month,
                year: year,
                color: global.theme_white,
                items: []
            }
            arr[n] = data;

            if (value == true) {
                day_++;
            }
            n++;
        }
        arr_months = arr;
        setCells(arr);

        // ZISKANIE ARRAY - JEDEN TYZDEN   
        arr = [];

        var curr = date; // get current date       
        var dow = curr.getDay();
        if (dow == 0) {
            dow = 7;
        }
        var first = curr.getDate() - dow;
        for (var i = 0; i < 7; i++) {
            var next = new Date(curr.getTime());
            next.setDate(first + i + 1);

            var d = next.getDate();
            var m = next.getMonth();
            var y = next.getFullYear();

            var data = {
                id: i,
                active: true,
                day: d,
                day_name: lang.days_short_label[i],
                month: m,
                year: y,
                color: global.theme_white,
                items: []
            }

            arr[i] = data;
        }
        arr_weeks = arr;
        setWeeks(arr);

        // ZISKANIE ARRAY - JEDEN DEN   
        var data = {
            id: 0,
            active: true,
            day: day,
            day_name: lang.days[day_of_week],
            month: month,
            year: year,
            color: global.theme_white,
            items: []
        }
        arr_day = data;
        setDays(data);

        if (reload == true) {
            db_items(typ_, arr_months, arr_weeks, arr_day, date);
        } else {
            Update(termins, typ_, arr_months, arr_weeks, arr_day, date);
        }
    }


    const Next = () => {
        if (typ == 0) {
            // MESACNY KALENDAR
            var d = day;
            var m = month + 1;
            var y = year;
            if (m > 11) {
                m = 0;
                y = y + 1;
            }
        }
        if (typ == 1) {
            // TYZDENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() + 7);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        if (typ == 2 || typ == 3) {
            // DENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() + 1);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        Redraw(typ, y, m, d, true);
    }

    const Prev = () => {
        if (typ == 0) {
            // MESACNY KALENDAR
            var d = day;
            var m = month - 1;
            var y = year;
            if (m < 0) {
                m = 11;
                y = y - 1;
            }
        }
        if (typ == 1) {
            // TYZDENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() - 7);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        if (typ == 2 || typ == 3) {
            // DENNY KALENDAR
            var date = actualDate;
            date.setDate(date.getDate() - 1);

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var date = new Date(y, m, d);
            setActualDate(date);
        }
        Redraw(typ, y, m, d, true);
    }

    const Today = () => {
        var data = new Date();
        var y = data.getFullYear();
        var m = data.getMonth();
        var d = data.getDate();

        var data = new Date(y, m, d);
        setActualDate(data);

        Redraw(typ, y, m, d, true);
    }

    const ChangeTyp = (typ_) => {
        if (typ_ >= 0) {
            setTyp(typ_);

            var date = actualDate;

            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            Redraw(typ_, y, m, d, true);
        }
    }


    const ChangeDay = (y, m, d) => {
        if (d > 0) {
            var data = new Date(y, m, d);
            setActualDate(data);
            Redraw(typ, y, m, d, false);
        }
    }

    const ChangeToDay = (y, m, d, t) => {
        var data = new Date(y, m, d);
        setActualDate(data);
        setTyp(t);
        Redraw(t, y, m, d, false);
    }

    const SelectDay = (y, m, d) => {
        if (d > 0) {
            var data = new Date(y, m, d);
            setActualDate(data);
            setDay(d);
            setMonth(m);
            setYear(y);
            //Redraw(typ, y, m, d, false);
            setRedrawAll(!redrawAll);
        }
    }

    const GetDays = (year, month) => {
        // POCET DNI V MESIAC
        return new Date(year, month, 0).getDate();
    }


    const GetDay = (year, month, day) => {
        // DEN V TYZDNI
        return new Date(year, month, day).getDay();
    }

    const GetDayAdd = (year, month, day, add) => {
        // PRIDAT / ODPOCITAT DNI
        var date = new Date(year, month, day);
        date.setDate(date.getDate() + add);

        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();

        return [d, m, y];
    }

    const GetMinutes = (hour, minute) => {
        // vrati pocet minut z hodin
        return (hour * 60) + minute;
    }

    const GetMinutes2 = (time_) => {
        // vrati pocet minut z hodin
        var time = time_.split(':');
        return (parseInt(time[0]) * 60) + parseInt(time[1]);
    }

    const GetHours = (minutes) => {
        // vrati hodiny z minut
        var hour = parseInt(minutes / 60);
        var minute = minutes - (hour * 60);
        return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
    }

    const CheckIntersect = (list, data, offset) => {
        // KONTROLA, CI SA DVA TERMINY KRIZUJU
        var result = 0;
        var process = true;
        list.forEach(item => {
            if (data.id == item.id) {
                process = false
            }
            if (process == true) {
                if (data.start >= item.start && data.start < item.end) {
                    result++;
                }
            }
        });
        return result * offset;
    }


    const GetDate = (date) => {
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());

        return y + '-' + m + '-' + d;
    }

    const GetDatum = (date) => {
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());

        return d + '.' + m + '.' + y;
    }

    const GetMonthDay = (d_, m_) => {
        var d = String(d_).padStart(2, '0');
        var m = String(m_ + 1).padStart(2, '0');

        return m + '-' + d;
    }

    const AddNew = (status, item) => {
        setEditing(status);
        setStartMinutes(480);
        if (status == true) {
            setSelected(item);
        }
        setShowAddNew(true);
    }

    const AddNewHour = (hour) => {
        var minutes = GetMinutes2(hour);
        setStartMinutes(minutes);
        setEditing(false);
        setShowAddNew(true);
    }

    const NewTerminPress = (typ, data) => {
        if (typ == true) {
            // ULOZIT            
            db_item_store(data);
        }
        setShowAddNew(false);
    }


    /* ************************************************************************************************
     
    UPRAVA / PRIDANIE NOVEHO TERMINU
     
    ************************************************************************************************ */
    const NewTermin = (props) => {
        // <NewTermin lang={lang} item={selected} editing={editing} func={NewTerminPress.bind(this)} />

        const [isBusy, setBusy] = useState(false);

        const [date, setDate] = useState(null);
        const [timeStart, setTimeStart] = useState('08:00');
        const [timeEnd, setTimeEnd] = useState('10:00');
        const [note, setNote] = useState('');
        const [color, setColor] = useState(0);

        const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
        const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
        const isSmall = useMediaQuery({ maxWidth: global.device_small });

        let { func } = props;
        var lang = props.lang;

        useEffect(() => {
            console.log(props);
            var date = new Date(props.actual);
            setDate(date);
            setTimeStart(GetHours(props.item.start));
            setTimeEnd(GetHours(props.item.end));
            return () => {
            };

        }, []);


        const Press = (typ, value) => {
            func(typ, value);
        }

        const Close = () => {
            Press(0, 0);
        }

        const Save = () => {
            // kontrola ci je END > START
            var date = new Date(props.actual);

            var d = String(date.getDate()).padStart(2, '0');
            var m = String(date.getMonth() + 1);
            var y = String(date.getFullYear());

            var time = GetMinutes2(timeStart);

            // DATA, KTORE SA NAHRAJU DO DB
            var data = {
                salon_id: props.salon.id,
                time: timeStart,
                date: GetDate(date),
                year: y,
                month: m,
                day: d,
                hour: parseInt(time / 60),
                minute: parseInt(time % 60),
                client_id: props.user.id,
                name: props.user.name,
                surname: props.user.surname,
                mobil: props.user.mobil,
                email: props.user.email,
                note: note,
                service_id: props.service.service_id == null ? 0 : props.service.service_id,
                end_time: timeEnd,
                group_id: props.service.group_id,
                service_name: props.service.label,
                service_time: props.service.time,
                verifed: false,
                service2_id: 0,
                service3_id: 0,
                service4_id: 0,
                service5_id: 0,
                reserve_system: true
            }

            Press(true, data);
        }

        return (
            <Dialog open={true} maxWidth={'lg'} fullScreen={isSmall ? true : false}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gold_dark, height: 70, justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.reserve_date}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_white, marginTop: 5, textAlign: 'center' }}>{props.service.label}</p>
                    </div>
                </div>

                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        {/* DATUM / CAS */}
                        <p style={{ ...styles.TextLarge }}>{lang.date}: <b>{GetDatum(selected.date)}</b></p>
                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.time}: <b>{timeStart} - {timeEnd}</b></p>

                        {/* POZNAMKA */}
                        <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 20 }}>
                            <p style={{ ...styles.TextSmall, marginBottom: 5 }}>{lang.note}</p>
                            <TextField multiline={true} rows={3} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} style={{ width: '100%' }} />
                        </div>

                        <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 10 }}>{lang.reserve_info}</p>
                    </div>
                </div>

                <div style={{ ...styles.Block, paddingBottom: 25, paddingTop: 10 }}>
                    <div style={{ ...styles.Block }}>
                        <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_green, width: 200, margin: 0 }}>{lang.save}</Button>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark, width: 200, marginTop: 30 }}>{lang.close}</Button>
                    </div>
                </div>
            </Dialog >
        )
    }
    /* *******************************************************************************************************************************
    **********************************************************************************************************************************
     
        RENDER - Main
     
    **********************************************************************************************************************************        
    ********************************************************************************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>

            {/* NADPIS */}
            {props.listing == false ?
                <div style={{ ...styles.Block, maxWidth: '100%', flexWrap: 'wrap', paddingBottom: 0, paddingTop: 5 }}>
                    <div style={{ ...styles.BlockRow, width: '100%', paddingBottom: 5, justifySelf: 'center' }}>
                        <div style={{ ...styles.Block, width: 50 }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 45, height: 45, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faAngleLeft} />
                            </IconButton>
                        </div>
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'normal' }}>{lang.reserve_date_text}</p>
                            <p style={{ ...styles.TextXXLarge, fontWeight: 'normal', marginTop: 5 }}><b>{typ == 2 || typ == 3 ? days.day + '.' : ''}{lang.months[month]}</b> {year}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 50 }}></div>
                    </div>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '30%' }}>
                        <div style={{ ...isSmall ? styles.BlockRow : styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: isSmall ? '25%' : 57, height: 40 }}>
                                <IconButton onClick={() => ChangeTyp(0)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: typ == 0 ? 'bold' : 'normal', color: typ == 0 ? typ_highlight : global.theme_medium_gray }}>{lang.month.toLowerCase()}</p>
                                </IconButton>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? '25%' : 57, height: 40 }}>
                                <IconButton onClick={() => ChangeTyp(1)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: typ == 1 ? 'bold' : 'normal', color: typ == 1 ? typ_highlight : global.theme_medium_gray }}>{lang.week.toLowerCase()}</p>
                                </IconButton>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? '25%' : 36, height: 40 }}>
                                <IconButton onClick={() => ChangeTyp(2)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: typ == 2 ? 'bold' : 'normal', color: typ == 2 ? typ_highlight : global.theme_medium_gray }}>{lang.day}</p>
                                </IconButton>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? '25%' : 40, height: 40 }}>
                                <IconButton onClick={() => ChangeTyp(3)} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 30, backgroundColor: undefined, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: typ == 3 ? typ_highlight : global.theme_medium_gray }} icon={faList} />
                                </IconButton>
                            </div>
                            {isSmall ? null :
                                <div style={{ ...styles.BlockRight, width: 50 }}>
                                    <div onClick={() => AddNew(false, [])} style={{ ...styles.BlockRound, backgroundColor: bg_new, paddingLeft: 0, paddingRight: 0, cursor: 'pointer' }}>
                                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faPlus} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                        <div style={{ ...styles.Block, width: 60 }}>
                            {(month == todayMonth && typ == 0) || (month == todayMonth && day == todayDay && typ == 2) ? null :
                                <Button onClick={() => Prev()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                    <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronLeft} />
                                </Button>
                            }
                        </div>
                        <div style={{ ...styles.Block, width: 60 }}>
                            <Button onClick={() => Next()} style={{ ...styles.ButtonRaw, width: '100%', height: 40, backgroundColor: global.theme_dark_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faChevronRight} />
                            </Button>
                        </div>
                        <div style={{ ...styles.Block, width: 120 }}>
                            <Button onClick={() => Today()} style={{ ...styles.ButtonRaw, width: '100%', textTransform: 'none', height: 40, backgroundColor: global.theme_light_gray, paddingLeft: 0, paddingRight: 0, borderRadius: 0 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.today}</p>
                            </Button>
                        </div>
                    </div>
                </div>
                : null}

            <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                <Box sx={{ width: '100%', height: 8 }}>
                    {isBusy == true ?
                        <LinearProgress />
                        :
                        <div style={{ width: '100%', height: 8 }}></div>
                    }
                </Box>

            </div>
            {/* PONDELOK ... NEDELA */}
            {typ < 2 ?
                <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                    {typ == 1 ? <div style={{ width: 42 }}></div> : null}
                    {lang.days_short_label.map((item, index) => (
                        <div key={index} style={{ ...styles.Block, width: isSmall ? typ == 1 ? '12.2%' : '15%' : typ == 1 ? boxWidth - 7 : boxWidth - 1, height: 25 }}>
                            <p style={{ ...styles.TextTiny, marginRight: 5, color: global.theme_dark_gray }}>{item}</p>
                        </div>
                    ))}
                </div>
                : null}

            {/* ****************************************************************************************************
             KALENDARNY DNI 
             **************************************************************************************************** */}
            {typ == 0 ?
                // MESACNY PREHLAD
                <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : boxWidth * 7, flexWrap: 'wrap' }}>
                    {cells.map((item, index) => (
                        <div onClick={() => ChangeToDay(item.year, item.month, item.day, 2)} key={index} style={{ width: isSmall ? '13.6%' : '13.82%', height: isSmall ? boxHeight / 2 : boxHeight, border: item.day == day ? '1px solid ' + global.theme_red : '1px solid ' + global.theme_light_gray, backgroundColor: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? highLight : item.active ? item.color : global.theme_gray, cursor: 'pointer' }}>
                            {item.active == true ?
                                <div style={{ ...styles.BlockRight, paddingTop: 3 }}>
                                    <div style={{ ...isSmall ? styles.BlockRight : styles.BlockRow }}>
                                        {isSmall ? null :
                                            <div style={{ width: '50%' }}>
                                                {item.sviatok ?
                                                    <p style={{ ...styles.TextTiny, marginRight: 5, color: global.theme_red }}>{lang.sviatok}</p>
                                                    : null}
                                            </div>
                                        }
                                        <div style={{ ...styles.BlockRight, width: isSmall ? '100%' : '50%' }}>
                                            <p style={{ ...styles.TextSmall, marginRight: 5, color: item.sviatok ? global.theme_red : global.theme_black }}>{item.day}</p>
                                        </div>
                                    </div>
                                    {isSmall ? null :
                                        <div style={{ ...styles.BlockLeft, paddingTop: 5 }}>
                                            {item.items.map((element, index) => (
                                                // ROZPIS V JEDNOM DNI
                                                <div key={index} style={{ width: '100%' }}>
                                                    {index < 4 ?
                                                        <div style={{ ...styles.BlockLeft, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                            <p style={{ ...styles.TextXXTiny, marginLeft: 5 }}>{GetHours(element.start)} {element.accepted == false ? <FontAwesomeIcon style={{ width: 12, height: 12, color: global.theme_red }} icon={faExclamationTriangle} /> : <b>{element.label}</b>}</p>
                                                        </div>
                                                        :
                                                        index == 4 ?
                                                            <p style={{ ...styles.TextXXTiny, marginLeft: 5 }}>...</p>
                                                            : null}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {item.count > 4 || (isSmall && item.count > 0) ?
                                        // ZNACKA KOLKO JE TERMINOV V DNI
                                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight, paddingTop: isSmall ? 5 : 0 }}>
                                            <div style={{ ...styles.BlockRound, width: 26, height: 26, backgroundColor: bg_roung, marginRight: isSmall ? 0 : 5 }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{item.count}</p>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>
                    ))}
                </div>
                : null}

            {
                typ == 1 ?
                    // TYZDENNY PREHLAD
                    <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : (boxWidth * 7), flexWrap: 'wrap' }}>
                        <div style={{ ...styles.Block, width: 42 }}>
                            <div style={{ height: 5 }}></div>
                            {hours.map((item, index) => (
                                <div key={index} style={{ ...styles.Block, height: dayHeight + 1 }}>
                                    <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray }}>{item == '00:00' ? '' : item}</p>
                                </div>
                            ))}
                        </div>

                        {weeks.map(item => (
                            <div key={item.id} onClick={() => ChangeToDay(item.year, item.month, item.day, 2)} style={{ width: isSmall ? '12.0%' : '13.3%', borderLeft: '1px solid ' + global.theme_gray, backgroundColor: item.year == todayYear && item.month == todayMonth && item.day == todayDay ? highLight : global.theme_white, cursor: 'pointer' }}>
                                <div style={{ ...styles.Block, height: 20, backgroundColor: item.day == day ? global.theme_gray : global.theme_back }}>
                                    <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, marginRight: 5, color: item.sviatok ? global.theme_red : global.theme_black }}>{item.day}.{item.month + 1}.</p>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    {hours.map((item, index) => (
                                        <div key={index} style={{ ...styles.Block, height: dayHeight, borderBottom: '1px solid ' + global.theme_gray, borderTop: item == start_line || item == end_line ? '1px solid black' : null }}></div>
                                    ))}

                                    {item.items.map(element => (
                                        <Paper key={element.id} onClick={() => AddNew(true, element)} elevation={isSmall ? 1 : 3} style={{ ...styles.BlockLeft, position: 'absolute', width: isSmall ? '80%' : boxWidth - 32, height: element.end - element.start < 40 ? 40 / (60 / dayHeight) : (element.end - element.start) / (60 / dayHeight), top: ((element.start) * 1.03) / (60 / dayHeight), left: CheckIntersect(item.items, element, 5), backgroundColor: bg_colors[element.color], border: '1px solid ' + global.theme_white, borderLeft: '3px solid ' + border_colors[element.color], borderRadius: 10, overflow: 'hidden' }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: isSmall ? 0 : 5, marginTop: 3 }}>{GetHours(element.start)}</p>
                                            {isSmall ? element.accepted == false ? <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_red }} icon={faExclamationTriangle} /> : null :
                                                <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, textAlign: 'left' }}>{element.accepted == false ? <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_red }} icon={faExclamationTriangle} /> : element.label}</p>
                                            }
                                        </Paper>
                                    ))}

                                </div>
                            </div>
                        ))}
                    </div>
                    : null
            }

            {
                typ == 2 ?
                    // DENNY PREHLAD
                    <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                        <p style={{ ...styles.TextLarge, marginBottom: 2, color: days.sviatok ? global.theme_red : global.theme_black }}>{days.day_name} {days.sviatok ? '(' + lang.sviatok + ')' : ''}</p>
                        <div style={{ ...styles.BlockRow, maxWidth: isSmall ? '100%' : (boxWidth * 7) }}>
                            <div style={{ ...styles.Block, width: 42 }}>
                                {hours.map((item, index) => (
                                    <div key={index} style={{ ...styles.BlockRaw, height: 61 }}>
                                        <p style={{ ...styles.TextXXTiny }}>{item == '00:00' ? '' : item}</p>
                                    </div>
                                ))}
                            </div>
                            <div style={{ ...styles.BlockLeft, position: 'relative', height: 24 * 61.3, width: isSmall ? '100%' : (boxWidth - 7) * 7, backgroundColor: bg_paper }}>
                                <div style={{ ...styles.Block, paddingTop: 6 }}>
                                    {hours.map((item, index) => (
                                        <div key={index} style={{ ...styles.Block, height: 60, borderBottom: '1px dotted ' + global.theme_light_gray, borderTop: item == start_line || item == end_line ? '1px solid black' : null }}>
                                        </div>
                                    ))}
                                </div>
                                {days.items.map((item, index) => (
                                    <Paper key={item.id} elevation={isSmall ? 1 : 6} onClick={() => AddNew(true, item)} style={{ ...styles.BlockLeft, position: 'absolute', width: isSmall ? '50%' : '75%', height: item.end - item.start < 30 ? 30 : item.end - item.start, top: 4 + (item.start * 1.018), left: (index % 2) * 40, backgroundColor: bg_colors[item.color], border: '1px solid ' + global.theme_white, borderLeft: '3px solid ' + border_colors[item.color], borderRadius: 10, overflow: 'hidden', cursor: 'pointer' }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3 }}><b>{GetHours(item.start)} - {GetHours(item.end)}</b></p>
                                        <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, textAlign: 'left' }}>{item.label}</p>
                                    </Paper>
                                ))}
                            </div>
                        </div>
                    </div>

                    : null
            }

            {
                typ == 3 ?
                    // DENNY LISTING
                    <div style={{ ...styles.Block, maxWidth: isSmall ? '100%' : boxWidth * 7 }}>
                        <div style={{ ...styles.BlockLeft, position: 'relative', maxWidth: isSmall ? '100%' : boxWidth * 7, marginTop: 10, marginBottom: 20 }}>

                            {days.items.map(item => (
                                <Paper key={item.id} elevation={0} onClick={() => AddNew(true, item)} style={{ ...styles.BlockLeft, width: '98%', minHeight: 60, backgroundColor: bg_colors[item.color], border: '1px solid ' + global.theme_white, borderLeft: '3px solid ' + border_colors[item.color], borderRadius: 10, overflow: 'hidden', cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 3, fontWeight: 'bold' }}>{item.accepted == false ? <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_red }} icon={faExclamationTriangle} /> : null} {GetHours(item.start)} - {GetHours(item.end)}</p>
                                    <p style={{ ...styles.TextTiny, marginLeft: 5, marginTop: 5, marginBottom: 5, textAlign: 'left' }}>{item.label}</p>
                                </Paper>
                            ))}

                            {days.items.length == 0 ?
                                <div style={{ ...styles.Block, backgroundColor: global.theme_white, paddingTop: 20, paddingBottom: 20 }}>
                                    <p style={{ ...styles.TextSmall }}>{lang.none}</p>
                                </div>
                                : null}
                        </div>
                    </div>

                    : null
            }

            {showAddNew == true ?
                <NewTermin salon={props.salon} user={props.user} service={props.service} lang={lang} actual={actualDate} item={selected} func={NewTerminPress.bind(this)} />
                : null}

            {/*
            isBusy == true ?
                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
                    <div style={{ ...styles.Block, marginLeft: 0, width: 50, height: 50, backgroundColor: global.theme_white, borderRadius: 25 }}>
                        <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={40} />
                    </div>
                </Backdrop>
                : null
            */}
        </div >
    )
};

