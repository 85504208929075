// **********************************************************************

global.testing = true;
global.title = 'Esthedermsalon';

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://client.esthedermsalon.com';
global.db_url = global.web + '/app_web/';

// **********************************************************************
global.web_salony = 'https://www.kozmeticke-salony.sk/';

// LINK NA OBRAZKY
global.web_images = 'https://www.kozmeticke-salony.sk/public/';

// **********************************************************************
// NASTAVENIA APLIKACIE
// **********************************************************************
global.version = '1.1';
global.items_max = 20; // pagination - max.items na stránke

// **********************************************************************
// TYPY UCTOV
// **********************************************************************

global.account_typ_disable = 0;
global.account_typ_admin = 1;
global.account_typ_salon = 2;
global.account_typ_client = 3;
global.account_typ_public = 4;
global.account_typ_agent = 5;
global.account_typ_agent_admin = 6;
global.account_typ_salon_test = 7;

// **********************************************************************
// STATUS OBJEDNAVOK TOVARU NA SKLAD
// **********************************************************************

global.booking_sended = 0;
global.booking_inproccess = 1;
global.booking_onway = 2;
global.booking_cancel_request = 3;
global.booking_finish = 5;
global.booking_cancel = 6;

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_lighter = '#BBD6FD';
global.theme_light = '#95B2DC';
global.theme_medium = '#5F7AA1';
global.theme_dark = '#3D5273';
global.theme_darker = '#29364A';

global.theme_lighter_blue = '#DEDEFF';
global.theme_light_blue = '#AED6F0';
global.theme_blue = '#2089DC';
global.theme_dark_blue = '#4D617B';

global.theme_lighter_red = '#FFCCCC';
global.theme_light_red = '#F77268';
global.theme_red = '#F44336';
global.theme_dark_red = '#C3170B';

global.theme_lighter_green = '#BBFFBB';
global.theme_light_green = '#A6FA9D';
global.theme_green = '#46F436';
global.theme_dark_green = '#18BB18';
global.theme_darker_green = '#15A115';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gold_lighter = '#E7DAD0';
global.theme_gold_light = '#B29B89';
global.theme_gold = '#DAC7B8';
global.theme_gold_medium = '#7B614D';
global.theme_gold_dark = '#706359';
global.theme_gold_darker = '#332E18';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xgray = '#CCCCCC';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu = '#000000';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu1 = '#aabbcc';
global.theme_submenu2 = '#ddbbbb';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_dark_orange = '#E34B04';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';


global.theme_protected = '#1C6A78';
global.theme_filter = '#FFBAB5';


// DIALOG
global.theme_dialog_back = 'linear-gradient(to right,#95B2DC,#BBD6FD)';
global.theme_dialog_label = '#000000';
global.theme_dialog_back_red = 'linear-gradient(to right,#AA0000,#FF8888)';

global.theme_gradient = 'linear-gradient(to right,#FFFFFF,#A5ECF7)';
global.theme_gradient_dark = 'linear-gradient(#7B614D,#DAC7B8)';
global.theme_menu_top_gradient = 'linear-gradient(to right,#3D5273,#3D5273)';

global.theme_mobile_background = 'linear-gradient(to bottom,#3D5273,#19263A)';
global.theme_mobile_background_white = 'linear-gradient(to bottom,#FFFFFF,#CCCCCC)';


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;
global.font_blog = 20;

// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 15;

// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.colors = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#444444' },
    { id: 2, color: '#777777' },
    { id: 3, color: '#AAAAAA' },
    { id: 4, color: '#CCCCCC' },
    { id: 5, color: '#E3E5F0' },
    { id: 6, color: '#F4F5FA' },
    { id: 7, color: '#FFFFFF' },

    { id: 13, color: '#0000AA' },
    { id: 8, color: '#0000FF' },
    { id: 14, color: '#509DF4' },
    { id: 15, color: '#ADCCEF' },
    { id: 10, color: '#00FFFF' },
    { id: 12, color: '#8957F4' },
    { id: 9, color: '#FF00FF' },
    { id: 26, color: '#FFAAFF' },


    { id: 11, color: '#FF0000' },
    { id: 21, color: '#F44336' },
    { id: 25, color: '#D8532B' },
    { id: 24, color: '#F77B72' },
    { id: 16, color: '#FFDDDD' },
    { id: 12, color: '#FFAA77' },
    { id: 17, color: '#FFFF00' },
    { id: 18, color: '#FFFF77' },

    { id: 19, color: '#004400' },
    { id: 20, color: '#4D7B4D' },
    { id: 23, color: '#00AA00' },
    { id: 22, color: '#00FF00' },
    { id: 17, color: '#C0E0B8' },
]

// **********************************************************************
// CHYBY APLIKACIE
// ********************************************************************** 
global.web_error = 'Application Error #100'; // zlý token - token nieje nájdený v DB, alebo zlý link


