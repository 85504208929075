/*
*
* =================================================================================================================
* NAKUPNY KOSIK
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { Button, Checkbox, Dialog, FormControlLabel, IconButton, TextField } from '@mui/material';
import { faAngleLeft, faAngleRight, faCashRegister, faCheck, faCheckCircle, faMinus, faPlus, faShoppingBag, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { DialogBonusSystem, DialogProduct, GetPrice, IsEmpty, Loading } from './items.js';
import { red } from '@mui/material/colors';
import { width } from '@mui/system';


export const Checkout = (props) => {
    /*

        USAGE:
            <Card lang={lang} language={language} user={user} salon={salon} func={CardResult.bind(this)} />

            const CardResult = (value) => {             
            }

    */

    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isBusy, setBusy] = useState(true);
    const [redraw, setRedraw] = useState(true);
    const [index, setIndex] = useState(0);
    const [error, setError] = useState('');
    const [showBonus, setShowBonus] = useState(false);
    // DATA
    const [salon, setSalon] = useState([]);
    const [cardSum, setCardSum] = useState(0);
    const [allSum, setAllSum] = useState(0);
    const [cardQuantity, setCardQuantity] = useState(0);
    const [items, setItems] = useState(false);
    const [payment, setPayment] = useState(false);
    const [paymentFiltered, setPaymentFiltered] = useState(false);
    const [paymentID, setPaymentID] = useState(0);
    const [paymentSelected, setPaymentSelected] = useState(false);
    const [trade, setTrade] = useState(false);
    const [tradeID, setTradeID] = useState(0);
    const [tradeSelected, setTradeSelected] = useState(false);

    // USERDATA
    const [user, setUser] = useState([]);
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState(false);
    const [street, setStreet] = useState('');
    const [streetErr, setStreetErr] = useState(false);
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPSC] = useState('');
    const [pscErr, setPSCErr] = useState(false);
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState(false);
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [note, setNote] = useState('');

    const [gdpr, setGdpr] = useState(false);

    const [orderNumber, setOrderNumber] = useState('');
    const [bonus, setBonus] = useState(10);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const menuHeight = 55;
    const bottomHeight = 60;
    const imageHeight = 70;

    // IMAGES
    const bag = require('./react/app/bag.png');


    let { func } = props;
    var lang = props.lang;

    const GetNullText = (txt) => {
        if (txt == null || txt == undefined) {
            return '';
        } else {
            return txt
        }
    }

    useEffect(() => {

        setName(GetNullText(props.user.name));
        setSurname(GetNullText(props.user.surname));
        setMobil(GetNullText(props.user.mobil));
        setEmail(GetNullText(props.user.email));
        setStreet(GetNullText(props.user.street));
        setStreetNumber(GetNullText(props.user.street_number));
        setPSC(GetNullText(props.user.psc));
        setTown(GetNullText(props.user.town));

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV

        try {
            const response = await fetch(
                global.db_url + 'card', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    user_id: props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    setCardSum(json.sum);
                    setCardQuantity(json.quantity);

                    setItems(json.card);

                    var tmp = json.trade;
                    if (json.trade_free_enabled == true && parseFloat(json.trade_free_price) <= parseFloat(json.sum)) {
                        tmp.forEach(item => {
                            item.price = 0;
                        });
                    }
                    setTrade(tmp);

                    setPayment(json.payment);
                    setPaymentFiltered(json.payment);

                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_checkout = async () => {
        // kupa produktu -> zmena mnozstva
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'checkout', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon.id,
                    user_id: props.user.id,
                    trade_id: tradeSelected.id,
                    payment_id: paymentSelected.id,
                    name: name,
                    surname: surname,
                    mobil: mobil,
                    email: email,
                    street: street,
                    street_number: streetNumber,
                    psc: psc,
                    town: town,
                    is_payment_free: tradeSelected.price == 0 ? true : false,
                    is_trade_free: paymentSelected.price == 0 ? true : false,
                    sum: allSum,
                    note: note
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                setBonus(json.bonus);
                setOrderNumber(json.order_number);
                setIndex(3);
            } else {
                setIndex(4);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ, cardQuantity);
    }

    const GetSum = (items) => {
        var sum = 0.00;
        items.forEach(item => {
            if (item.quantity > 0) {
                sum += parseInt(item.quantity) * parseFloat(item.eur).toFixed(2);
            }
        });

        sum = GetPrice(sum);

        return sum;
    }

    const ChangeTrade = (item) => {
        var tmp = payment.filter(x => x.cash == item.cash);
        setPaymentFiltered(tmp);
        setTradeID(item.id);
        setPaymentID(0);

        var tmp = trade.find(x => x.id == item.id);
        setTradeSelected(tmp);
    }

    const ChangePayment = (id) => {
        setPaymentID(id);

        var tmp = payment.find(x => x.id == id);
        setPaymentSelected(tmp);

        var sum = parseFloat(cardSum) + parseFloat(tmp.price) + parseFloat(tradeSelected.price);
        setAllSum(sum);
    }

    const Checkout = () => {
        db_checkout();
    }

    const CheckData = () => {
        var error = false;

        setError('');
        setNameErr(false);
        setSurnameErr(false);
        setEmailErr(false);
        setStreetErr(false);
        setPSCErr(false);
        setTownErr(false);

        error += IsEmpty(name) == true ? setNameErr(true) ?? true : setNameErr(false) ?? false;
        error += IsEmpty(surname) == true ? setSurnameErr(true) ?? true : setSurnameErr(false) ?? false;
        error += IsEmpty(email) == true ? setEmailErr(true) ?? true : setEmailErr(false) ?? false;
        //error = nonEmail(email) == true ? setEmailErr(true) ?? true : setEmailErr(false) ?? false;
        if (tradeSelected.need_adress == true) {
            error += IsEmpty(street) == true ? setStreetErr(true) ?? true : setStreetErr(false) ?? false;
            error += IsEmpty(psc) == true ? setPSCErr(true) ?? true : setPSCErr(false) ?? false;
            error += IsEmpty(town) == true ? setTownErr(true) ?? true : setTownErr(false) ?? false;

        }

        if (error == 0) {
            setIndex(2);
        } else {
            setError(lang.required);
        }
    }


    const DialogBonusSystemResult = (value) => {
        setShowBonus(false);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Dialog open={true} fullScreen={true} >
            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_gold_lighter, height: menuHeight }}>

                    <div style={{ ...styles.Block, width: 60, height: menuHeight }}></div>

                    <div style={{ ...styles.Block, height: menuHeight }}>
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <FontAwesomeIcon style={{ height: 18, color: global.theme_black, marginRight: 10 }} icon={faCashRegister} />
                                <p style={{ ...styles.TextNormal, marginTop: 0, fontWeight: '600' }}>{lang.checkout}</p>
                            </div>
                            <p style={{ ...styles.TextTiny }}>{index == 0 ? lang.checkout_step_1 : index == 1 ? lang.checkout_step_2 : lang.checkout_step_3}</p>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 60, height: menuHeight }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>

                {index == 0 && isBusy == false ?
                    // *********************************************************************************************************
                    // VYBER DOPRAVY A PLATBY
                    // *********************************************************************************************************
                    <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight }}>
                        <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight - bottomHeight, overflowY: 'scroll' }}>
                            {/* DOPRAVA */}
                            <div style={{ ...styles.Block }}>
                                <p style={{ ...styles.TextLarge, marginTop: 15 }}>{lang.trade}</p>
                                <div style={{ ...styles.Block, width: '95%', paddingTop: 10 }}>
                                    {
                                        trade != false ?
                                            trade.map((item, index) => (
                                                <div key={item.id} style={{ ...styles.BlockLeft, marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid ' + global.theme_gray }}>
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.BlockLeft, width: 30 }}>
                                                            <FormControlLabel label={''}
                                                                control={<Checkbox checked={item.id == tradeID} size={'small'} onChange={() => ChangeTrade(item)} />}
                                                            />
                                                        </div>
                                                        <div onClick={() => ChangeTrade(item)} style={{ ...styles.BlockLeft, paddingTop: 9, cursor: 'pointer' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.label}</p>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.note}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, paddingTop: 5, paddingBottom: 5 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.price == 0 ? lang.free : item.price + ' ' + lang.money}</p>
                                                    </div>
                                                </div>
                                            ))
                                            : null
                                    }
                                </div>
                            </div>

                            {/* PLATBA */}
                            <div style={{ ...styles.Block, marginTop: 30 }}>
                                {paymentFiltered != false && tradeID > 0 ?
                                    <p style={{ ...styles.TextLarge }}>{lang.payment}</p>
                                    : null}
                                <div style={{ ...styles.Block, width: '95%', paddingTop: 10 }}>
                                    {
                                        paymentFiltered != false && tradeID > 0 ?
                                            paymentFiltered.map((item, index) => (
                                                <div key={item.id} style={{ ...styles.BlockLeft, marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid ' + global.theme_gray }}>
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.BlockLeft, width: 30 }}>
                                                            <FormControlLabel label={''}
                                                                control={<Checkbox checked={item.id == paymentID} size={'small'} onChange={() => ChangePayment(item.id)} />}
                                                            />
                                                        </div>
                                                        <div onClick={() => ChangePayment(item.id)} style={{ ...styles.BlockLeft, paddingTop: 9, cursor: 'pointer' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.label}</p>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.note}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, paddingTop: 5, paddingBottom: 5 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.price == 0 ? lang.free : item.price + ' ' + lang.money}</p>
                                                    </div>
                                                </div>
                                            ))
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: global.theme_gray }}>
                            {paymentID > 0 ?

                                <div style={{ ...styles.BlockRow, width: '95%', height: bottomHeight }}>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: bottomHeight, justifyContent: 'center' }}>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '70%', height: bottomHeight, justifyContent: 'center' }}>
                                        <Button onClick={() => setIndex(1)} style={{ ...styles.ButtonDark, color: global.theme_white, width: '95%' }}>
                                            {lang.continue}
                                            <FontAwesomeIcon style={{ width: 10, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                        </Button>

                                    </div>
                                </div>
                                : null}
                        </div>

                    </div>
                    : null}


                {index == 1 ?
                    // *********************************************************************************************************
                    // DODACIA ADRESA - KONTAKTNE UDAJE
                    // *********************************************************************************************************
                    <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight, overflowY: 'scroll' }}>

                        <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight, overflowY: 'scroll' }}>

                            <div style={{ ...styles.BlockCenter, width: '90%' }}>
                                <div style={{ ...styles.Block, paddingBottom: 10, marginTop: 10 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.client_contact}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.name}</p>
                                    <TextField
                                        value={name}
                                        onInput={e => setName(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.label}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white, height: 30 },
                                        }}
                                        variant="outlined"
                                        error={nameErr}
                                        helperText={nameErr == '' ? '' : lang.required}
                                    />
                                </div>

                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.surname}</p>
                                    <TextField
                                        value={surname}
                                        onInput={e => setSurname(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.label}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white, height: 30 },
                                        }}
                                        variant="outlined"
                                        error={surnameErr}
                                        helperText={surnameErr == '' ? '' : lang.required}
                                    />
                                </div>

                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.email}</p>
                                    <TextField
                                        value={email}
                                        onInput={e => setEmail(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.label}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white, height: 30 },
                                        }}
                                        variant="outlined"
                                        error={emailErr}
                                        helperText={emailErr == '' ? '' : lang.required}
                                    />
                                </div>

                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.mobil}</p>
                                    <TextField
                                        value={mobil}
                                        onInput={e => setMobil(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.label}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white, height: 30 },
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>

                            {tradeSelected.need_adress == true ?
                                <div style={{ ...styles.Block, width: '90%', marginTop: 30 }}>
                                    <div style={{ ...styles.Block, paddingBottom: 10 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.client_address}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                            <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.street}</p>
                                            <TextField
                                                value={street}
                                                onInput={e => setStreet(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, height: 30 },
                                                }}
                                                variant="outlined"
                                                error={streetErr}
                                                helperText={streetErr == '' ? '' : lang.required}
                                            />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.number}</p>
                                            <TextField
                                                value={streetNumber}
                                                onInput={e => setStreetNumber(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, height: 30 },
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                        <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                            <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.psc}</p>
                                            <TextField
                                                value={psc}
                                                onInput={e => setPSC(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, height: 30 },
                                                }}
                                                variant="outlined"
                                                error={pscErr}
                                                helperText={pscErr == '' ? '' : lang.required}
                                            />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.town}</p>
                                            <TextField
                                                value={town}
                                                onInput={e => setTown(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '100%' }}
                                                placeholder={lang.label}
                                                InputProps={{
                                                    style: { backgroundColor: global.theme_white, height: 30 },
                                                }}
                                                variant="outlined"
                                                error={townErr}
                                                helperText={townErr == '' ? '' : lang.required}
                                            />
                                        </div>
                                    </div>

                                </div>
                                : null}

                            <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextLabel, marginBottom: 2 }}>{lang.order_note}</p>
                                    <TextField
                                        value={note}
                                        onInput={e => setNote(e.target.value)}
                                        multiline={true}
                                        rows={2}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.label}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white },
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                                <div style={{ ...styles.Block, height: tradeSelected.need_adress == true ? 300 : 400 }}></div>
                            </div>

                        </div>

                        <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.BlockRow, height: bottomHeight, width: '95%' }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: bottomHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonLight, width: '100%' }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                        {lang.back}
                                    </Button>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '70%', height: bottomHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => CheckData()} style={{ ...styles.ButtonDark, color: global.theme_white, width: '95%' }}>
                                        {lang.continue}
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}


                {index == 2 ?
                    // *********************************************************************************************************
                    // ZHRNUTIE
                    // *********************************************************************************************************
                    <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight }}>

                        <div style={{ ...styles.BlockCenter, height: screenHeight - menuHeight - bottomHeight, overflowY: 'scroll' }}>
                            <div style={{ ...styles.BlockCenter, width: '95%', marginTop: 10 }}>
                                {/* SUMA ZA PRODUKTY */}
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.sum_products}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{GetPrice(cardSum)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* DOPRAVA */}
                                <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.trade_}</p>
                                        <p style={{ ...styles.TextTiny }}>{tradeSelected.label}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{GetPrice(tradeSelected.price)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* PLATBA */}
                                <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.payment_}</p>
                                        <p style={{ ...styles.TextTiny }}>{paymentSelected.label}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{GetPrice(paymentSelected.price)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* SUMA K ZAPLATENIU */}
                                <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{lang.sum_to_pay}</p>
                                        <p style={{ ...styles.TextTiny }}>{lang.dph_price}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{GetPrice(allSum)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* KONTAKTNE UDAJE */}
                                <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.client_contact}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{name} {surname}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.email}: {email}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.mobil}: {mobil}</p>
                                </div>

                                {/* ADRESA */}
                                {tradeSelected.need_adress == true ?
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.client_address}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{name} {surname}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{street} {streetNumber}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{psc} {town}</p>
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ width: 28 }}>
                                                <Checkbox checked={gdpr} size={'small'} onChange={() => setGdpr(!gdpr)} style={{ margin: 0, padding: 0 }} />

                                            </div>
                                            <div onClick={() => setGdpr(!gdpr)} style={{ ...styles.BlockLeft, cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left' }}>{lang.gdpr_agree}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div style={{ ...styles.Block, height: bottomHeight, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.BlockRow, width: '95%', height: bottomHeight }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: bottomHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => setIndex(1)} style={{ ...styles.ButtonLight, width: '100%' }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                        {lang.back}
                                    </Button>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '70%', height: bottomHeight, justifyContent: 'center' }}>
                                    <Button onClick={() => Checkout()} disabled={gdpr == true ? false : true} style={{ ...styles.ButtonDark, backgroundColor: gdpr == true ? global.theme_darker_green : '#CCCCCC', width: '95%', color: global.theme_white, padding: 0, margin: 0 }}>
                                        <p style={{ ...styles.TextXTiny, color: global.theme_white }}>
                                            {lang.send_order}
                                        </p>
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}


                {index == 3 ?
                    // *********************************************************************************************************
                    // UKONCENA OBJEDNAVKA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, width: '90%', height: screenHeight - menuHeight }}>
                        <FontAwesomeIcon style={{ height: 40, color: global.theme_dark_green }} icon={faCheckCircle} />
                        <p style={{ ...styles.TextTiny, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{lang.shopping_finish_ok}</p>
                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center', marginTop: 20 }}>{lang.order_number}</p>
                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center' }}>{orderNumber}</p>
                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center', marginTop: 20 }}>{lang.shopping_finish_ok_text}:</p>
                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center', marginTop: 5 }}>{email}</p>
                        {bonus > 0 && props.salon.score == true ?
                            <div style={{ ...styles.Block }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'center', marginTop: 40 }}>{lang.order_score}</p>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'center', marginTop: 0 }}>{bonus} {lang.get_credits}</p>
                                <Button onClick={() => setShowBonus(true)} style={{ ...styles.ButtonLink, color: global.theme_blue, fontWeight: '400', marginTop: 20 }}>{lang.bonus_info}</Button>
                            </div>
                            : null}
                    </div>
                    : null}

                {index == 4 ?
                    // *********************************************************************************************************
                    // CHYBA
                    // *********************************************************************************************************
                    <div style={{ ...styles.Block, width: '90%', height: screenHeight - menuHeight }}>
                        <FontAwesomeIcon style={{ height: 40, color: global.theme_dark_red }} icon={faXmarkCircle} />
                        <p style={{ ...styles.TextNormal, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{lang.order_error}</p>
                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'center', marginTop: 5 }}>{lang.order_error_}</p>
                        <Button onClick={() => setIndex(2)} style={{ ...styles.ButtonDark, marginTop: 50 }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                            {lang.back}
                        </Button>
                    </div>
                    : null}


            </div>

            {showBonus == true ?
                <DialogBonusSystem lang={lang} language={language} salon_id={props.salon.id} func={DialogBonusSystemResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </Dialog>
    )
};

