/*
*
* =================================================================================================================
* E-SHOP
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { Loading, DialogProduct, DialogProductBuy } from './items.js';
import { Badge, Button, Chip, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';
import { faAngleLeft, faGift, faShoppingBag, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Card } from './card.js';
import { Checkout } from './checkout.js';


export const Eshop = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isBusy, setBusy] = useState(true);
    const [banners, setBanners] = useState(false);
    const [section, setSection] = useState(0);
    const [category, setCategory] = useState(0);
    const [categoryID, setCategoryID] = useState(false);
    const [showProduct, setShowProduct] = useState(false);
    const [itemSelectedID, setItemSelectedID] = useState(0);
    const [itemSelected, setItemSelected] = useState(false);
    const [showBuy, setShowBuy] = useState(false);
    const [cardQuantity, setCardQuantity] = useState(0);
    const [showCard, setShowCard] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    const [search, setSearch] = useState('');

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const titleHeight = 60;
    const tileHeight = 300;
    const imageHeight = 120;
    const textHeight = 95;
    const buyHeight = 40;


    // IMAGES
    const logo = require('./react/app/esthedermsalon_transparent.png');
    const logo_ie = require('./react/app/institut_logo_gold.png');
    const eshop_face = require('./react/app/eshop_tvar.jpg');
    const eshop_body = require('./react/app/eshop_telo.jpg');
    const eshop_summer = require('./react/app/eshop_leto.jpg');
    const eshop_action = require('./react/app/eshop_action.jpg');

    var lang = language == 0 ? sk : cz;
    const params = useParams();
    const token = params.token;

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE

        try {
            const response = await fetch(
                global.db_url + 'eshop', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    setLanguage(json.language);
                    var tmp = json.products;

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setItems(tmp);
                    PageInit(tmp);

                    setBanners(json.banners);

                    // pocet produktov v košíku
                    setCardQuantity(json.quantity);

                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            } else {
                navigate('/mobile/error');
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_get_card = async () => {
        // MNOZSTVO PRODUKTOV V KOSIKU

        try {
            const response = await fetch(
                global.db_url + 'card_quantity', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon.id,
                    user_id: user.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCardQuantity(json.quantity);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }


    const Redraw = (items, p, search, category) => {
        // REDRAW PRODUKTOV

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            var data = items.filter(x => FindWords(x.label, x.keywords, words));
        } else {
            if (category != false && category != 999) {
                // filtrovanie vybratej kategórie
                var data = items.filter(x => category.includes(parseInt(x.category_id)));
            } else {
                if (category == 999) {
                    // akciova kategoria
                    var data = items.filter(x => x.action == true);
                } else {
                    // vsetky produkty
                    var data = items;
                }
            }
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        if (p > 0) {
            GoToStart();
        } else {
            GoToUp();
        }

    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, categoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_menu');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const GoToUp = () => {
        setTimeout(() => {
            const element = document.getElementById('page_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ProductShow = (id) => {
        setItemSelectedID(id);
        setShowProduct(true);
    }

    const DialogProductResult = (typ) => {
        db_get_card();
        if (typ == true) {
            setShowCard(true);
            var timer = setTimeout(() => {
                setShowProduct(false);
            }, 200);
        } else {
            setShowProduct(false);
        }

    }

    const ChangeCategory = (index, id, category) => {
        setSection(index);
        setCategoryID(id);
        setCategory(category);
        Redraw(items, 0, '', id);
        if (index == 0) {
            GoToUp();
        } else {
            GoToStart();
        }
    }

    const SearchProduct = (_search) => {
        setSearch(_search);
        Redraw(items, 0, _search, 0);
    }

    const SearchReset = () => {
        setSearch('');
        Redraw(items, 0, '', categoryID);
    }

    const ProductBuy = (item) => {
        setItemSelected(item);
        setShowBuy(true);
    }

    const DialogProductBuyResult = (typ, quantity) => {
        setCardQuantity(quantity);
        setShowBuy(false);
        if (typ == true) {
            setShowCard(true);
        }
    }

    const CardResult = (value, quantity) => {
        setCardQuantity(quantity);

        db_get_card();
        if (value == true) {
            // K POKLADNI
            setShowCheckout(true);
            var timer = setTimeout(() => {
                setShowCard(false);
            }, 250);
        } else {
            setShowCard(false);
        }
    }

    const CheckoutResult = (value) => {
        setShowCheckout(false);
        db_get_card();
        if (value == true) {
            // PREBEHOL NAKUP
        }
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_gold_lighter }}>
            {/* TITLE BAR */}
            <div style={{ ...styles.Block, height: titleHeight, backgroundColor: global.theme_gold_dark }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockRowRaw, alignItems: 'flex-start', width: '90%', height: titleHeight }}>
                        <div style={{ ...styles.Block, height: titleHeight, width: titleHeight + 10 }}>
                            <img src={logo} style={{ height: '100%', maxHeight: titleHeight - 10, objectFit: 'contain', marginLeft: 10 }}></img>
                        </div>
                        <div style={{ ...styles.Block, height: titleHeight }}>
                            <TextField
                                value={search}
                                onInput={e => SearchProduct(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: '80%' }}
                                placeholder={lang.product_search}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {

                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => SearchReset()} style={{ width: 20, height: 20 }}>
                                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                        </IconButton>
                                    </InputAdornment>,
                                    style: { backgroundColor: global.theme_white, borderRadius: 18, height: 36 },
                                }}
                                variant="outlined"
                            />
                        </div>

                    </div>
                    <div style={{ ...styles.Block, width: 60, height: titleHeight }}>
                        <IconButton onClick={() => setShowCard(true)} style={{ width: 40, height: 40, backgroundColor: global.theme_white }}>
                            <Badge badgeContent={cardQuantity > 99 ? 'XX' : cardQuantity} color="primary">
                                <FontAwesomeIcon style={{ height: 17, color: global.theme_black }} icon={faShoppingCart} />
                            </Badge>
                        </IconButton>
                    </div>
                </div>
            </div>

            {/* ZOZNAM PRODUKTOV, KATEGORIE, BANNERY ... */}
            <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap', height: screenHeight - titleHeight, overflowY: 'scroll' }}>
                <div id={'page_start'} style={{ ...styles.Block }}></div>

                {/* BANNER */}
                {banners != false && search == '' ?
                    <div style={{ ...styles.Block }}>
                        <img src={global.web_images + banners[0].link_mobil} style={{ width: '100%', maxWidth: '100%', objectFit: 'contain' }}></img>
                    </div>
                    : null}

                <div id={'id_menu'} style={{ ...styles.Block }}></div>
                {/* LOGO INSTITUT ESTHEDERM */}
                {search == '' ?
                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                        <img src={logo_ie} style={{ width: '100%', maxHeight: 50, objectFit: 'contain' }}></img>
                    </div>
                    : null}

                {/* ------------------------------------------------------------------------------------
                    MENU
                ------------------------------------------------------------------------------------ */}
                {search == '' ?
                    <div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 0 }}>
                        {section == 0 ?
                            // HLAVNA KATEGÓRIA
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.categories}</p>

                                <Button onClick={() => ChangeCategory(1, [lang.category_prevychova_id, lang.category_antiage_id, lang.category_intensive_id, lang.category_cistenie_id], 0)} style={{ ...styles.ButtonLight, width: 240, color: global.theme_black, marginTop: 20 }}>{lang.section_face}</Button>
                                <Button onClick={() => ChangeCategory(2, [lang.category_telo_id], 0)} style={{ ...styles.ButtonLight, width: 240, color: global.theme_black, marginTop: 10 }}>{lang.section_body}</Button>
                                <Button onClick={() => ChangeCategory(3, [lang.category_leto_id], 0)} style={{ ...styles.ButtonLight, width: 240, color: global.theme_black, marginTop: 10 }}>{lang.section_summer}</Button>
                                <Button onClick={() => ChangeCategory(4, 999, 0)} style={{ ...styles.ButtonLight, width: 240, color: global.theme_black, marginTop: 10 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_gold_dark, marginRight: 10 }} icon={faGift} />
                                    {lang.section_action}
                                </Button>
                            </div>
                            : null}

                        {section == 1 ?
                            // STAROSTLIVOST O TVAR
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, paddingBottom: 40 }}>
                                    <img src={eshop_face} style={{ width: '100%', maxHeight: 100, objectFit: 'contain' }}></img>
                                    <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '600' }}>{lang.section_face}</p>
                                </div>

                                <Button onClick={() => ChangeCategory(0, false, 0)} style={{ ...styles.ButtonDark, width: 260, color: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                    {lang.categories_all}
                                </Button>
                                <Button onClick={() => ChangeCategory(1, [lang.category_prevychova_id], lang.category_prevychova_id)} style={{ ...category == lang.category_prevychova_id ? styles.ButtonSelect : styles.ButtonLight, width: 240, marginTop: 10 }}>{lang.category_prevychova}</Button>
                                <Button onClick={() => ChangeCategory(1, [lang.category_antiage_id], lang.category_antiage_id)} style={{ ...category == lang.category_antiage_id ? styles.ButtonSelect : styles.ButtonLight, width: 240, marginTop: 10 }}>{lang.category_antiage}</Button>
                                <Button onClick={() => ChangeCategory(1, [lang.category_intensive_id], lang.category_intensive_id)} style={{ ...category == lang.category_intensive_id ? styles.ButtonSelect : styles.ButtonLight, width: 240, marginTop: 10 }}>{lang.category_intensive}</Button>
                                <Button onClick={() => ChangeCategory(1, [lang.category_cistenie_id], lang.category_cistenie_id)} style={{ ...category == lang.category_cistenie_id ? styles.ButtonSelect : styles.ButtonLight, width: 240, marginTop: 10 }}>{lang.category_cistenie}</Button>
                            </div>
                            : null}


                        {section == 2 ?
                            // STAROSTLIVOST O TELO
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, paddingBottom: 40 }}>
                                    <img src={eshop_body} style={{ width: '100%', maxHeight: 100, objectFit: 'contain' }}></img>
                                    <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '600' }}>{lang.section_body}</p>
                                </div>

                                <Button onClick={() => ChangeCategory(0, false, 0)} style={{ ...styles.ButtonDark, width: 240, color: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                    {lang.categories_all}
                                </Button>
                            </div>
                            : null}

                        {section == 3 ?
                            // OPALOVANIE
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, paddingBottom: 40 }}>
                                    <img src={eshop_summer} style={{ width: '100%', maxHeight: 100, objectFit: 'contain' }}></img>
                                    <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '600' }}>{lang.section_summer}</p>
                                </div>

                                <Button onClick={() => ChangeCategory(0, false)} style={{ ...styles.ButtonDark, width: 240, color: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                    {lang.categories_all}
                                </Button>
                            </div>
                            : null}

                        {section == 4 ?
                            // AKCIA
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, paddingBottom: 40 }}>
                                    <img src={eshop_action} style={{ width: '100%', maxHeight: 100, objectFit: 'contain' }}></img>
                                    <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '600' }}>{lang.section_action}</p>
                                </div>

                                <Button onClick={() => ChangeCategory(0, false)} style={{ ...styles.ButtonDark, width: 240, color: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                    {lang.categories_all}
                                </Button>
                            </div>
                            : null}

                    </div>
                    :
                    <div style={{ ...styles.Block, paddingTop: 5, paddingBottom: 5 }}>
                        <p style={{ ...styles.TextTiny }}>{lang.search_string}</p>
                        <Chip label={search} onDelete={() => SearchReset()} style={{ marginTop: 5 }} />

                    </div>
                }

                {/* ------------------------------------------------------------------------------------
                        ZOZNAM PRODUKTOV 
                ------------------------------------------------------------------------------------ */}
                {itemsFiltered != false ?
                    itemsFiltered.map((item, index) => (
                        <div key={item.id} id={'item_' + index} style={{ ...styles.Block, width: '50%', paddingTop: 10, paddingBottom: 10, cursor: 'pointer', borderTopLeftRadius: global.radius, borderTopRightRadius: global.radius }}>
                            <div style={{ ...styles.BlockCenter, width: '90%', height: tileHeight, border: '1px solid ' + global.theme_gold, backgroundColor: global.theme_white, borderTopLeftRadius: global.radius, borderTopRightRadius: global.radius }}>
                                <div onClick={() => ProductShow(item.id)} style={{ ...styles.Block, height: tileHeight - buyHeight }}>
                                    <div style={{ ...styles.BlockCenter, height: imageHeight }}>
                                        <img src={global.web_images + item.image} style={{ width: '100%', height: '100%', maxWidth: imageHeight, maxHeight: imageHeight, objectFit: 'contain', margin: 5 }} />
                                    </div>
                                    <div style={{ ...styles.BlockCenter, width: '95%', height: tileHeight - imageHeight - buyHeight }}>
                                        <div style={{ ...styles.BlockCenter, height: textHeight }}>
                                            <p style={{ ...styles.TextSmall, marginTop: 5, color: global.theme_black }}>{item.label}</p>
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 0, color: global.theme_dark_gray }}>{item.eur} {lang.money}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: item.instock > 0 ? global.theme_dark_green : global.theme_dark_orange }}>{item.instock > 0 ? lang.instock : lang.no_instock}</p>
                                    </div>
                                </div>
                                <div onClick={() => ProductBuy(item)} style={{ ...styles.Block, height: buyHeight, backgroundColor: global.theme_gold_dark }}>
                                    <Button style={{ ...styles.ButtonLink, color: global.theme_white }}>{lang.buy}</Button>
                                </div>
                            </div>
                        </div>
                    ))
                    : null
                }


                {itemsFiltered != false ?
                    pageMax > 1 ?
                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                        </div>
                        : null
                    : null}

            </div>

            {showProduct == true ?
                <DialogProduct buy={true} lang={lang} salon={salon} user={user} language={language} product_id={itemSelectedID} func={DialogProductResult.bind(this)} />
                : null}

            {showBuy == true ?
                <DialogProductBuy lang={lang} salon={salon} user={user} quantity={1} product={itemSelected} card_quantity={cardQuantity} func={DialogProductBuyResult.bind(this)} />
                : null}

            {showCard == true ?
                <Card lang={lang} language={language} user={user} salon={salon} func={CardResult.bind(this)} />
                : null}

            {showCheckout == true ?
                <Checkout lang={lang} language={language} user={user} salon={salon} func={CheckoutResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading />
                : null}
        </div >
    )
};

