/*
*
* =================================================================================================================
* CHAT - diskusia
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import { faComments, faMessage, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { DialogAsk, DialogChatAdd, DialogImagePreview, Loading } from './items.js';


export const Chat = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isBusy, setBusy] = useState(true);

    // DATA
    const [salon, setSalon] = useState(false);
    const [user, setUser] = useState(false);
    const [chat, setChat] = useState(false);
    const [chatLastID, setChatLastID] = useState(0);
    const [showChatAdd, setShowChatAdd] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [image, setImage] = useState(false);
    const [imageDate, setImageDate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // CHAT - pocet poloziek zobrazených
    const limitChat = 100;

    // RIADKY
    const rowRadius = 20;
    const rowPadding = 7;
    const rowOffset = 15;
    const rowTextOffset = 10;
    const imageSize = 150;
    const imageRadius = 10;

    const titleHeight = 40;
    const bottomHeight = 70;

    // IMAGES
    const logo = require('./react/app/empty_list.png');


    var lang = language == 0 ? sk : cz;
    const params = useParams();
    const token = params.token;

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get(0);

        return () => {
        };

    }, []);

    const db_get = async (offset) => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'chat', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    limit: limitChat,
                    offset: offset
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    setSalon(json.salon);
                    setUser(json.user);
                    var tmp = json.forum;
                    if (tmp != false) {
                        tmp.sort((a, b) => {
                            if (a.date > b.date) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        setChat(tmp);
                        var lastID = tmp[tmp.length - 1].id;
                        setChatLastID(lastID);

                        GoToLast(lastID);

                    } else {
                        setChat(false);
                    }

                } else {
                    navigate('/mobile/error');
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async () => {
        // VYMAZANIE PRISPEVKU
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'chat_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: itemSelected.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get(0);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GoToStart = () => {
        var timer = setTimeout(() => {
            const element = document.getElementById('chat_0');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const GoToLast = (id) => {

        var timer = setTimeout(() => {
            const element = document.getElementById('chat_' + id);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    }

    const AddNew = () => {
        // NOVY PRISPEVOK
        setShowChatAdd(true);
    }

    const EditMessage = (item) => {
        // UPRAVA SPRAVY / VYMAZANIE
        if (item.user_id == user.id) {
            setItemSelected(item);
            setShowDelete(true);
        }
    }

    const DialogAskResult = (value) => {
        setShowDelete(false);
        if (value == true) {
            // VYMAZAT PRISPEVOK
            db_delete();
        }
    }

    const PreviewImage = (item) => {
        // FULLSCREEN NAHLAD OBRAZKA
        if (item.image != null && item.image != '') {
            if (item.image.substr(0, 5) == 'https') {
                setImage(item.image);
            } else {
                setImage(global.web_salony + item.image);
            }
            setImageDate(item.datum);
        }
        setShowImagePreview(true);
    }

    const DialogImagePreviewResult = () => {
        setShowImagePreview(false);
    }

    const DialogChatAddResult = (typ) => {
        setShowChatAdd(false);
        if (typ == true) {
            db_get(0);
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.Block, backgroundColor: global.theme_gold_lighter, height: titleHeight }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <FontAwesomeIcon style={{ height: 20, color: global.theme_gold_dark, marginRight: 10 }} icon={faComments} />
                    <p style={{ ...styles.TextLarge, color: global.theme_black }}>{lang.chat}</p>

                </div>
            </div>
            {salon != false ? salon.forum_enabled == true ?
                <div style={{ ...styles.BlockCenter, width: '95%', height: screenHeight - titleHeight - bottomHeight, overflowY: 'scroll' }}>

                    {
                        chat != false ?
                            chat.map((item, index) => (
                                <div id={'chat_' + item.id} key={item.id} style={{ ...styles.Block, marginTop: rowOffset, marginBottom: rowOffset }}>
                                    <div onClick={() => EditMessage(item)} style={{ ...styles.Block, cursor: 'pointer' }}>
                                        <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray, marginBottom: 10, marginTop: 10 }}>{item.datum}</p>
                                    </div>
                                    {item.user_id == user.id ?
                                        // PRISPEVKY ZAKAZNIKA
                                        <div style={{ ...styles.BlockRight }}>
                                            {item.image != null && item.image != '' ?
                                                <div style={{ ...styles.BlockRight }}>
                                                    <img onClick={() => PreviewImage(item)} src={item.image.substr(0, 5) == 'https' ? item.image : global.web_salony + item.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginRight: rowTextOffset, cursor: 'pointer' }}></img>
                                                </div>
                                                : null}
                                            {item.text != null && item.text != '' ?
                                                <div onClick={() => EditMessage(item)} style={{ ...styles.Block, width: '75%', backgroundColor: global.theme_light_blue, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderBottomRightRadius: 0, cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                        <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{item.text}</p>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        :
                                        // OSTATNE PRISPEVKY
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, marginLeft: rowTextOffset }}>{item.user_id == salon.id ? lang.salon : item.name}</p>
                                            {item.image != null && item.image != '' ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <img onClick={() => PreviewImage(item)} src={item.image.substr(0, 5) == 'https' ? item.image : global.web_salony + item.image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginLeft: rowTextOffset, cursor: 'pointer' }}></img>
                                                </div>
                                                : null}
                                            {item.text != null && item.text != '' ?
                                                <div style={{ ...styles.Block, width: '75%', marginTop: 2, backgroundColor: global.theme_gray, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderTopLeftRadius: 0 }}>
                                                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.text}</p>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    }
                                </div>
                            ))
                            :
                            <div style={{ ...styles.Block, height: screenHeight }}>
                                {isBusy == false ?
                                    <p style={{ ...styles.TextSmall }}>{lang.chat_none}</p>
                                    : null}
                            </div>
                    }
                </div>
                : null : null}

            {/* VKLADANIE PRISPEVKOV */}
            {salon != false ? salon.forum_enabled == true ?
                <div style={{ ...styles.Block, backgroundColor: global.theme_gold_lighter, height: bottomHeight }}>
                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, width: 240 }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                        {lang.chat_new}
                    </Button>
                </div>
                :
                <div style={{ ...styles.Block, height: screenHeight - titleHeight }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxHeight: 100, maxWidth: 100, objectFit: 'contain', borderRadius: 10 }}></img>
                    <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.chat_turn_off}</p>
                </div>
                : null}

            {showChatAdd == true ?
                <DialogChatAdd salon_id={salon.id} user_id={user.id} name={user.name + ' ' + user.surname} lang={lang} language={language} salon={salon} user={user} func={DialogChatAddResult.bind(this)} />
                : null}

            {showImagePreview == true ?
                <DialogImagePreview lang={lang} language={language} image={image} sub_label={imageDate} func={DialogImagePreviewResult.bind(this)} />
                : null}

            {showDelete == true ?
                <DialogAsk lang={lang} language={language} label={lang.chat_delete} text={lang.chat_delete_ask} func={DialogAskResult.bind(this)} />
                : null}
            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div>
    )
};

