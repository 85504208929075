/*
*
* =================================================================================================================
* HLAVNA STRÁNKA
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams, Navigate } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { faAngleLeft, faCalendarDays, faEnvelope, faEnvelopeOpenText, faMobile, faMobileAlt, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Line, MenuTop, DivInfo, Newsletter, DialogService, DialogMessage, DialogPDF, Loading, GetImageLink, DialogAsk, Debug } from './items.js';
import { Backdrop, Button, CircularProgress, getToolbarUtilityClass, IconButton, InputAdornment, TextField } from '@mui/material';
import { Reserve } from './reserve.js';


export const Main = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(0);

    // APP
    const [isLoading, setLoading] = useState(true);
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);
    const [settings, setSettings] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [termins, setTermins] = useState(false);
    const [notify, setNotify] = useState(false);
    const [services, setServices] = useState(false);
    const [servicesTyp, setServicesTyp] = useState(1);
    const [showService, setShowService] = useState(false);
    const [serviceSelected, setServiceSelected] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [showReserve, setShowReserve] = useState(false);
    const [showReserveCancel, setShowReserveCancel] = useState(false);
    const [terminSelected, setTerminSelected] = useState(false);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // IMAGES
    const logo = require('./react/app/institut_logo_gold.png');
    const logo_ie = require('./react/app/institut_logo.png');
    const logo_esthedermsalon = require('./react/app/esthedermsalon.jpg');
    const logo_esthedermsalon_black = require('./react/app/esthedermsalon_black.png');
    const background = require('./react/app/background.jpg');
    const background2 = require('./react/app/background_2.jpg');
    const img_tvar = require('./react/app/ie_face.png');
    const img_telo = require('./react/app/ie_body.png');
    const img_ruky = require('./react/app/ie_hands.png');
    const banner_template = require('./react/app/salon_banner.png');
    const photo_template = require('./react/app/salon_photo.jpg');
    const img_service = require('./react/app/service.png');


    var lang = language == 0 ? sk : cz;
    const params = useParams();
    const token = params.token;
    const timer = useRef();

    useEffect(() => {

        // ZISKANIE UDAJOV O SALONE A ZAKAZNIKOVI CEZ TOKEN
        db_get(true);

        if (timer.current == undefined) {
            timer.current = setInterval(() => {

                db_get(false);
            }, (15 * 60 * 1000));

        }

        return () => {
        };

    }, []);

    const db_get = async (show_loading) => {
        // AKTUALIZACIA UDAJOV POMOCOU TOKENU APLIKACIE
        if (show_loading == true) {
            setLoading(true);
        }

        try {
            const response = await fetch(
                global.db_url + 'main', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.salon != false && json.user != false) {
                    console.log(json.salon);
                    setSalon(json.salon);
                    setUser(json.user);
                    setSettings(json.settings);
                    setLanguage(json.language);
                    setNewsletter(json.newsletter);
                    if (json.termins != false) {
                        setTermins(json.termins);
                    }
                    if (json.notify != false) {
                        setNotify(json.notify);
                    }
                    var services_ = json.services;
                    services_.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setServices(services_);

                } else {
                    navigate('/mobile/error');
                }
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_notify_delete = async (id) => {
        // VYMAZANIE NOTIFIKACIE
        try {
            const response = await fetch(
                global.db_url + 'notify_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setNotify(false);
            }

        } catch (error) {
            console.log(error);
        }
    }


    const db_calendar_cancel = async (data) => {
        // ZRUSENIE REZERVACIE
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'calendar_cancel', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    data: data
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                terminSelected.deleted = true;
            }

            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const MenuTopResult = (value) => {
        db_get(false);
        setIndex(value);
        GoToStart();
        db_get(true);
    }

    const DivInfoResult = (typ, value) => {
        if (typ == true) {
            db_notify_delete(value);
        }
    }

    const NewsletterResult = () => {
        // ZOBRAZIT NEWSLETTER
        setShowPDF(true)
    }

    const DialogPDFResult = (value) => {
        setShowPDF(false);
    }


    const Message = () => {
        setShowMessage(true);
    }

    const ServiceSelect = (item) => {
        setServiceSelected(item);
        setShowService(true);
    }

    const ServicesChange = (typ) => {
        setServicesTyp(typ);
    }

    const DivServiceResult = (value) => {
        if (value == true) {
            db_get(true);
            setIndex(2);
        }
        setShowService(false);
    }

    const DialogMessageResult = (value) => {
        setShowMessage(false);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('start_id');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const GotoServices = () => {
        setIndex(1);
        GoToStart();
    }

    const ReserveResult = (value) => {
        if (value == true) {
            db_get(true);
            setIndex(2);
        }
        setShowReserve(false);
    }

    const CancelReservation = (item) => {
        setTerminSelected(item);
        setShowReserveCancel(true);
    }

    const CancelReservationCancel = (value) => {
        setShowReserveCancel(false);

        if (value == true) {

            let data = {
                id: terminSelected.id,
                deleted: true
            }

            console.log(data);
            db_calendar_cancel(data);
        }
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, paddingTop: 0 }}>

            {/* LOADING
            <Backdrop style={{ backgroundColor: global.theme_white, zIndex: 999 }} open={isLoading}>
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, objectFit: 'contain' }}></img>
            </Backdrop>
            */}

            {/* MENU TOP */}
            {index < 3 ?
                <MenuTop lang={lang} index={index} func={MenuTopResult.bind(this)} />
                : null}

            <div style={{ ...styles.Block, overflowY: 'scroll', marginTop: 0 }}>
                {index < 3 ?
                    <div id={'start_id'} style={{ ...styles.Block, height: 65 }}></div>
                    : null}

                {index == 0 ?
                    /*
                    *
                    * =================================================================================================================
                    * INFORMACIE
                    * =================================================================================================================
                    *
                    */

                    <div style={{ ...styles.Block, marginTop: 10 }}>

                        {/* NOTIFIKACIA blížiaceho sa ošetrenia */}
                        {termins != false ?
                            <div onClick={() => setIndex(2)} style={{ ...styles.Block, cursor: 'pointer' }}>
                                <DivInfo typ={0} lang={lang} language={language} label={lang.termin_next} text={termins[0].datum + ' ' + termins[0].time+'-'+termins[0].end_time} sub_text={termins[0].service_name} func={DivInfoResult.bind(this)} />
                            </div>
                            : null}

                        {/* NOTIFIKACIA zo systému */}
                        {notify != false ?
                            <DivInfo typ={1} lang={lang} language={language} id={notify.id} label={notify.label} text={notify.message} func={DivInfoResult.bind(this)} />
                            : null}

                        {/* oznam zo salonu */}
                        {settings != false ?
                            settings.info != '' ?
                                <DivInfo typ={3} lang={lang} language={language} label={lang.announcement} text={settings.info} func={DivInfoResult.bind(this)} />
                                : null
                            : null}

                        {/* LOGO IE + NAZOV SALONA */}
                        <div style={{ ...styles.Block, marginTop: 40, backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', paddingTop: 20, paddingBottom: 50 }}>
                            <div style={{ width: '100%', height: 480 }}>
                                <img src={logo} style={{ width: 160, height: 160, maxWidth: 160, objectFit: 'contain' }}></img>
                            </div>
                            <div style={{ ...styles.Block, width: '90%', marginTop: 40 }}>
                                {/* NEWSLETTER */}
                                {newsletter != false ?
                                    newsletter.enabled == true ?
                                        <Newsletter lang={lang} language={language} label={newsletter.description} text={newsletter.label} newsletter={newsletter} func={NewsletterResult.bind(this)} />
                                        : null
                                    : null}
                                {/* SALON - NAZOV */}
                                <p style={{ ...styles.TextNormal, textAlign: 'center', color: global.theme_gold_darker, fontFamily: 'Kalam', fontWeight: '400', marginTop: 20 }}>{lang.salon}</p>
                                <Line width={'20%'} offset={2} color={global.theme_black}></Line>
                                <p style={{ ...styles.TextLarge, color: global.theme_black, fontWeight: '600', marginBottom: 0, marginTop: 5, textAlign: 'center' }}>{isLoading == true ? <Skeleton variant="text" animation="wave" width={300} /> : salon.salon_name}</p>
                            </div>

                            {settings != false ?
                                <p style={{ ...styles.TextSmall, color: global.theme_medium_gray, fontWeight: '400', marginTop: 20, textAlign: 'center' }}>{isLoading == true ? <Skeleton variant="text" animation="wave" width={300} /> : settings.label}</p>
                                : null}

                        </div>

                        {/* BANNER - 1*/}
                        {settings != false ?
                            settings.banner != '' && settings.banner != null ?
                                <img loading='lazy' src={GetImageLink(settings.banner)} style={{ width: '100%', height: '100%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 0 }}></img>
                                :
                                <img loading='lazy' src={banner_template} style={{ width: '100%', height: '100%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 0 }}></img>
                            :
                            <img loading='lazy' src={banner_template} style={{ width: '100%', height: '100%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 0 }}></img>
                        }

                        <div style={{ ...styles.Block, marginTop: 0 }}>
                            {settings != false ?
                                <div style={{ ...styles.Block, width: '90%', marginTop: 40, paddingBottom: 40 }}>
                                    <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: settings.text }} />
                                </div>
                                : null}
                        </div>

                        {/* BANNER - 2 */}
                        {settings != false ?
                            settings.banner2 != '' && settings.banner2 != null ?
                                <img loading='lazy' src={GetImageLink(settings.banner2)} style={{ width: '100%', height: '100%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 0 }}></img>
                                : null
                            : null}

                        <div style={{ ...styles.Block }}>
                            {/* OSETRENIA */}
                            <div style={{ ...styles.Block, paddingTop: 50, paddingBottom: 40, backgroundColor: global.theme_gold_dark }}>
                                {/* LOGO IE */}
                                <img src={logo_ie} style={{ width: '100%', height: '100%', maxWidth: 100, objectFit: 'contain' }}></img>

                                <div style={{ ...styles.Block, width: '90%', paddingBottom: 20, paddingTop: 50 }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_white, textAlign: 'center', fontWeight: '600' }}>{lang.ie_text2}</p>
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                    <div style={{ ...styles.Block, width: '33%' }}>
                                        <img src={img_tvar} style={{ width: '90%', height: '90%', maxWidth: '90%', objectFit: 'contain', borderRadius: 10 }}></img>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 10 }}>{lang.service_face}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '33%' }}>
                                        <img src={img_telo} style={{ width: '90%', height: '90%', maxWidth: '90%', objectFit: 'contain', borderRadius: 10 }}></img>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 10 }}>{lang.service_body}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '33%' }}>
                                        <img src={img_ruky} style={{ width: '90%', height: '90%', maxWidth: '90%', objectFit: 'contain', borderRadius: 10 }}></img>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 10 }}>{lang.service_hands}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, width: '90%', paddingTop: 40 }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_white, textAlign: 'center' }}>{lang.ie_text3}</p>
                                </div>


                                {/* osetrenia */}
                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    <Button onClick={() => GotoServices()} style={{ ...styles.ButtonWhite }}>{lang.services_offer}</Button>
                                </div>

                            </div>

                            {/* PHOTO */}
                            {settings != false ?
                                settings.photo != '' && settings.photo != null ?
                                    <img loading='lazy' src={GetImageLink(settings.photo)} style={{ width: '70%', height: '70%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 40, borderRadius: global.radius / 2 }}></img>
                                    :
                                    <img loading='lazy' src={photo_template} style={{ width: '70%', height: '70%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 40, borderRadius: global.radius / 2 }}></img>
                                :
                                <img loading='lazy' src={photo_template} style={{ width: '70%', height: '70%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 40, borderRadius: global.radius / 2 }}></img>
                            }

                            {/* KONTAKT */}
                            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                <p style={{ ...styles.TextXXLarge, fontFamily: 'Kalam', fontWeight: '400' }}>{lang.contact}</p>
                                <Line width={'25%'} offset={0} color={global.theme_gold}></Line>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 15 }}>{salon.salon_name}</p>
                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 5 }}>{salon.salon_street} {salon.salon_street_number}</p>
                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 5 }}>{salon.salon_psc} {salon.salon_town}</p>
                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 15 }}><FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faMobileAlt} /> {salon.mobil}</p>
                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 5 }}><FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faEnvelope} /> {salon.email}</p>
                            </div>

                            {/* BANNER - 3*/}
                            {settings != false ?
                                settings.banner3 != '' && settings.banner3 != null ?
                                    <img loading='lazy' src={GetImageLink(settings.banner3)} style={{ width: '100%', height: '100%', maxWidth: screenWidth, maxHeight: undefined, objectFit: 'contain', marginTop: 0 }}></img>
                                    : null
                                : null}

                            {/* OTVARACIE HODINY */}
                            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 0, backgroundColor: global.theme_white, marginTop: 20, borderTopRightRadius: global.radius * 2 }}>
                                {settings != false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextXXLarge, fontFamily: 'Kalam', fontWeight: '400' }}>{lang.hours}</p>
                                        <Line width={'25%'} offset={0} color={global.theme_light_gray}></Line>
                                        <div style={{ ...styles.Block, marginTop: 15, paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[1]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_1}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[2]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_2}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[3]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_3}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[4]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_4}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[5]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_5}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[6]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_6}</span></p>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 5, paddingTop: 4, paddingBottom: 8, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', color: global.theme_dark_red }}>{lang.days[7]}:<span style={{ ...styles.TextSmall, marginLeft: 10 }}>{settings.hours_7}</span></p>
                                        </div>

                                    </div>
                                    : null}

                                {/* POSLAŤ SPRAVU */}
                                <div style={{ ...styles.Block, marginTop: 60 }}>
                                    <Button onClick={() => Message()} style={{ ...styles.ButtonWhite }}>{lang.send_message}</Button>
                                </div>

                                {/* OBJEDNAŤ SA */}
                                {salon.calendar_public == true ?
                                    <div style={{ ...styles.Block }}>
                                        <Button onClick={() => setShowReserve(true)} style={{ ...styles.ButtonWhite, marginTop: 20 }}>
                                            <FontAwesomeIcon style={{ width: 10, color: global.theme_gold_dark, marginRight: 10 }} icon={faCalendarDays} />
                                            {lang.reserve}
                                        </Button>
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, marginTop: 60, backgroundImage: `url(${background2})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', paddingTop: 560, paddingBottom: 40 }}>
                                    <div style={{ ...styles.Block, width: '95%', backgroundColor: '#FFFFFFAA', marginTop: 20, paddingTop: 20, paddingBottom: 20, borderRadius: global.radius }}>
                                        <div style={{ ...styles.Block, width: '90%' }}>
                                            <p style={{ ...styles.TextNormal, color: global.theme_gold_darker, fontWeight: '400', textAlign: 'center' }}>{lang.ie_text4}</p>
                                        </div>
                                    </div>
                                    {/*
                                    <img src={logo_esthedermsalon_black} style={{ width: '100%', height: '100%', maxWidth: 100, objectFit: 'contain', marginTop: 40 }}></img>
                                    */}
                                    <img src={logo_esthedermsalon} style={{ width: '100%', height: '100%', maxWidth: 150, marginTop: 40, objectFit: 'contain' }}></img>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 1 ?
                    /*
                    *
                    * =================================================================================================================
                    * SLUZBY - OSETRENIA
                    * =================================================================================================================
                    *
                    */

                    <div style={{ ...styles.Block, marginTop: 10, paddingBottom: 40 }}>
                        <p style={{ ...styles.TextXXLarge, fontFamily: 'Kalam', fontWeight: '400' }}>{lang.services_offer}</p>
                        <Line width={'25%'} offset={0} color={global.theme_black}></Line>

                        <div style={{ ...styles.BlockRow, paddingTop: 15, paddingBottom: 20 }}>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <Button onClick={() => ServicesChange(1)} style={{ ...servicesTyp == 1 ? styles.ButtonDark : styles.ButtonWhite, width: '90%', maxWidth: 180, height: 36, borderRadius: 18 }}>
                                    <p style={{ ...styles.TextXTiny, color: servicesTyp == 1 ? global.theme_white : global.theme_gold_dark }}>{lang.ie}</p>
                                </Button>
                            </div>
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <Button onClick={() => ServicesChange(2)} style={{ ...servicesTyp == 2 ? styles.ButtonDark : styles.ButtonWhite, width: '90%', maxWidth: 180, height: 36, borderRadius: 18 }}>
                                    <p style={{ ...styles.TextXTiny, color: servicesTyp == 2 ? global.theme_white : global.theme_gold_dark }}>{lang.others_services}</p>
                                </Button>
                            </div>
                        </div>


                        {services != false ?
                            services.map((item, index) => (
                                item.group_id == servicesTyp ?
                                    <div key={item.id} onClick={() => ServiceSelect(item)} style={{ ...styles.Block, borderBottom: '1px solid ' + global.theme_gold, paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }} >
                                        {servicesTyp == 1 ?
                                            // OSETRENIA INSTITUT ESTHEDERM
                                            <div style={{ ...styles.BlockRow }} >
                                                <div style={{ ...styles.Block, width: 100 }}>
                                                    <img src={img_service} style={{ width: 60, height: 60, borderRadius: 0 }} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '97%' }}>
                                                        <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray, margin: 0 }}>{lang.ie}</p>
                                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5, textAlign: 'left' }}>{item.label}</p>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_black, marginTop: 2, textAlign: 'left' }}>{item.description}</p>
                                                        <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, marginTop: 10, textAlign: 'left' }}>{lang.service_time}: {item.time} {lang.minute}</p>
                                                        <div style={{ ...styles.BlockRight }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_gold_dark, marginTop: 0, textAlign: 'left' }}>{lang.price}: {item.eur} {lang.money}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            // OSTATNE OSETRENIA
                                            <div style={{ ...styles.Block }} >
                                                <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '97%' }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5, textAlign: 'left' }}>{item.label}</p>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_black, marginTop: 2, textAlign: 'left' }}>{item.description}</p>
                                                        <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, marginTop: 10, textAlign: 'left' }}>{lang.service_time}: {item.time} {lang.minute}</p>
                                                        <div style={{ ...styles.BlockRight }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_gold_dark, marginTop: 0, textAlign: 'left' }}>{lang.price}: {item.eur} {lang.money}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : null
                            ))
                            : null
                        }
                    </div>
                    : null}


                {index == 2 ?
                    /*
                    *
                    * =================================================================================================================
                    * KALENDAR
                    * =================================================================================================================
                    *
                    */

                    <div style={{ ...styles.Block, marginTop: 10 }}>
                        <p style={{ ...styles.TextXXLarge, fontFamily: 'Kalam', fontWeight: '400' }}>{lang.reservations}</p>
                        <Line width={'25%'} offset={10} color={global.theme_black}></Line>
                        {
                            termins != false ?
                                termins.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10, backgroundColor: global.theme_gray, marginTop: 5, marginBottom: 5, borderTopRightRadius: global.radius * 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 60 }}>
                                                <FontAwesomeIcon style={{ height: 30, color: global.theme_gold_dark }} icon={faCalendarDays} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.date}: {item.datum}</p>
                                                <p style={{ ...styles.TextTiny }}>{lang.time}: {item.time}-{item.end_time}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 15 }}>{lang.planned_service}</p>
                                                <p style={{ ...styles.TextSmall, marginTop: 3 }}>{item.service_name}</p>
                                                <p style={{ ...styles.TextSmall }}>{item.service_name2}</p>
                                                <p style={{ ...styles.TextSmall }}>{item.service_name3}</p>
                                                <p style={{ ...styles.TextSmall }}>{item.service_name4}</p>
                                                <p style={{ ...styles.TextSmall }}>{item.service_name5}</p>
                                                {item.verifed == false ?
                                                    <p style={{ ...styles.TextSmall, marginTop: 10, color: global.theme_dark_red, fontWeight: '600' }}>{lang.reserve_waiting}</p>
                                                    : null}

                                                {item.deleted == 0 ?
                                                    <div style={{ ...styles.BlockRight, marginTop: 15 }}>
                                                        <Button onClick={() => CancelReservation(item)} style={{ ...styles.ButtonWhite, height: 36 }}>{lang.cancel_reservation}</Button>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockRight, marginTop: 15 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{lang.canceled_reservation}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                ))
                                :
                                <p style={{ ...styles.TextTiny, marginTop: 20 }}>{lang.planned_service_none}</p>
                        }
                    </div>
                    : null}


                {showService == true ?
                    <DialogService lang={lang} language={language} item={serviceSelected} user={user} salon={salon} height={screenHeight} func={DivServiceResult.bind(this)} />
                    : null}

                {showMessage == true ?
                    <DialogMessage lang={lang} language={language} salon={salon} user={user} height={screenHeight} func={DialogMessageResult.bind(this)} />
                    : null}

                {showPDF == true ?
                    <DialogPDF lang={lang} language={language} newsletter={newsletter} func={DialogPDFResult.bind(this)} />
                    : null}

                {showReserve == true ?
                    <Reserve lang={lang} language={language} salon={salon} user={user} height={screenHeight} func={ReserveResult.bind(this)} />
                    : null}

                {showReserveCancel == true ?
                    <DialogAsk lang={lang} language={language} label={lang.reservation_cancel} text={lang.reservation_cancel_ask} func={CancelReservationCancel.bind(this)} />
                    : null}

            </div>

            {isBusy == true ?
                <Loading></Loading>
                : null}

            {isLoading == true ?
                <Loading></Loading>
                : null}
        </div >
    )
};

