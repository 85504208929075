/*
*
* =================================================================================================================
* NESPRAVNY TOKEN - CHYBA
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk, cz } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import Skeleton from '@mui/material/Skeleton';
import { faFaceFrown, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';


export const Error = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    // APP
    const [isBusy, setBusy] = useState(true);

    // DATA
    const [salon, setSalon] = useState([]);
    const [user, setUser] = useState([]);

    // DIMENSIONS
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // IMAGES
    //const logo = require('./react/app/logo.png');


    var lang = language;
    const params = useParams();
    const token = params.token;

    useEffect(() => {

        return () => {
        };

    }, []);


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, width: screenWidth, height: screenHeight }}>
            <FontAwesomeIcon style={{ height: 40, color: global.theme_red }} icon={faFaceFrown} />
            <p style={{ ...styles.TextSmall, color: global.theme_black, marginTop:10 }}>
                {global.web_error}</p>
        </div>
    )
};

